<template>
  <validation-provider :customMessages="customMessages"  :name="name" :rules="{'required' : required === true ? { allowFalse: false } : false}"
                       v-slot="{ errors }">
    <label class="w-full flex cursor-pointer"
           :class="{'items-center': !alignTop, 'justify-between flex-row-reverse': checkboxRight, [textSize]: true}">
      <span class="border-2 border-solid rounded-md flex justify-center items-center p-2"
            :class="{'bg-primary border-primary': value,
              'w-12 h-12': !small,
              'w-10 h-10': small,
              'mr-4': !checkboxRight,
              'ml-4': checkboxRight,
              'border-report-error': errors.length,
              'border-gray-medium': outlineDark && !errors.length && !value,
              'border-gray-400': !outlineDark && !errors.length}">
        <BigCheck class="text-white" :class="{'opacity-0': !value}" />
      </span>
      <input
        hidden
        :value="value"
        :checked="value === true"
        @change="$emit('change', $event.target.checked)"
        class="text-brand outline-none focus:ring-0 focus:ring-offset-0 flex-grow"
        type="checkbox" :name="name">
      <ClickableText :class="{'text-surface-medium': !dark}" :text="label" />
    </label>
    <Errors v-if="errors.length" :errors="errors"/>
  </validation-provider>
</template>
<!--:customMessages="{required : 'toto'}" -->

<script>
 import {ValidationProvider} from "vee-validate";
import Errors from "@/components/form/Errors"
import ClickableText from "@/components/ClickableText"
import BigCheck from "@/assets/svg/big_check.svg?inline"

export default {
  components: {ClickableText, ValidationProvider, Errors, BigCheck},
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value : {
      type : Boolean,
      required : true
    },
    alignTop: Boolean,
    required: {
      type: Boolean,
      default: false
    },
    small: Boolean,
    dark: Boolean,
    outlineDark: Boolean,
    name: {
      type: String,
    },
    label: {
      type: String,
      default: ''
    },
    textSize: {
      type: String,
      default: 'text-14'
    },
    checkboxRight: Boolean,
    customMessages : Object
  }
}
</script>
