var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:{'text-white bg-primary border-primary': _vm.active,
                 'rounded-full': _vm.rounded,
                 'border border-solid border-gray-400 hover:bg-primary hover:text-white hover:border-primary': _vm.border,
                 'bg-white border border-solid border-white': _vm.plain,
                 'p-3': _vm.xs && !_vm.noPadding,
                 'p-4': !_vm.small && !_vm.xs && !_vm.noPadding,
                 'p-5': _vm.small && !_vm.noPadding,
                 'pointer-events-none': _vm.loading,
                 'shadow-blur': _vm.shadow},attrs:{"type":"button"}},[(!_vm.loading)?_c('Icon',{class:{
                      'w-5 h-5': _vm.xs,
                      'w-6 h-6': !_vm.xs && _vm.small,
                      'w-8 h-8': !_vm.xs && !_vm.small},attrs:{"icon":_vm.icon}}):_c('Loading',{class:{'w-6 h-6': _vm.small, 'w-8 h-8': !_vm.small}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }