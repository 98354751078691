var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.handleVisibilityChange),expression:"handleVisibilityChange"}],staticClass:"vue-recycle-scroller loadmore-wrap relative",class:{
    ready: _vm.ready,
    'page-mode': _vm.pageMode,
    [`direction-${_vm.direction}`]: true,
    'overscroll-contain': !_vm.finished && _vm.$screen.xs
  },on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[(_vm.$slots.beforeHeader)?_c('div',{staticClass:"vue-recycle-scroller__slot relative z-20"},[_vm._t("beforeHeader")],2):_vm._e(),_vm._v(" "),(_vm.$slots.header)?_c('div',{staticClass:"vue-recycle-scroller__slot",class:{[`sticky z-50 ${_vm.stickyTopDistance}`]: _vm.isStickyHeader}},[_vm._t("header")],2):_vm._e(),_vm._v(" "),_vm._t("before"),_vm._v(" "),(_vm.totalSize === 0 && _vm.showEmptyLoader)?_c('Loading'):_vm._e(),_vm._v(" "),_c('div',{ref:"wrapper",staticClass:"vue-recycle-scroller__item-wrapper refresh-track",style:({
      transform: _vm.distance ? `translate3d(0, ${_vm.distance}px, 0)` : '',
      webkitTransform: _vm.distance ? `translate3d(0, ${_vm.distance}px, 0)` : '',
      transitionDuration: `${_vm.duration}ms`,
      [_vm.direction === 'vertical' ? 'minHeight' : 'minWidth']: _vm.totalSize + 'px'
    })},[(_vm.onRefresh)?_c('div',{staticClass:"refresh-head",style:(_vm.headStyle)},[_c('Loading')],1):_vm._e(),_vm._v(" "),_vm._l((_vm.pool),function(view){return _c('div',{key:view.nr.id,staticClass:"vue-recycle-scroller__item-view",class:{ hover: _vm.hoverKey === view.nr.key },style:(_vm.ready ? { transform: `translate${_vm.direction === 'vertical' ? 'Y' : 'X'}(${view.position}px)` } : null),on:{"mouseenter":function($event){_vm.hoverKey = view.nr.key},"mouseleave":function($event){_vm.hoverKey = null}}},[_vm._t("default",null,{"item":view.item,"index":view.nr.index,"active":view.nr.used})],2)})],2),_vm._v(" "),(_vm.$slots.after)?_c('div',{staticClass:"vue-recycle-scroller__slot"},[_vm._t("after")],2):_vm._e(),_vm._v(" "),_c('div',{ref:"placeholder",staticClass:"loadmore"},[(_vm.loadLoading && !_vm.finished && !_vm.error)?_c('div',{staticClass:"loadmore-loading"},[_c('Loading')],1):_vm._e()]),_vm._v(" "),_c('ResizeObserver',{on:{"notify":_vm.handleResize}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }