<template>
  <div class="h-full w-full fixed top-0 left-0 h-[100vh] z-[999999]">
    <transition name="slide-to-left">
      <FirstStep v-if="currentStepIndex === 0" @next="incrementIndex" />
      <SecondStep v-if="currentStepIndex === 1" @next="enableNotifications" @skip="skipNotifications" />
    </transition>

  </div>
</template>

<script>
import FirstStep from "@/components/splashcreen/FirstStep.vue"
import SecondStep from "@/components/splashcreen/SecondStep.vue"

export default {
  name: 'IntroductionSteps',
  components: {SecondStep, FirstStep},
  data: () => ({
    isLoading: true,
    currentStepIndex: 0,
    stepsLength: 3,
  }),
  computed: {
    isLastStep() {
      return this.currentStepIndex === this.stepsLength - 1;
    },
  },
  async mounted() {
    this.isLoading = false;
  },
  methods: {
    incrementIndex() {
      if (this.isLastStep) {
        this.close();
      } else {
        this.currentStepIndex++;
      }
    },
    enableNotifications() {
      this.$emit('enable-notifications');
    },
    skipNotifications() {
      this.$emit('skip-notifications');
    },
  }
}
</script>

<style scoped lang="scss">
$padding: 30px;
$Power2EaseOut: cubic-bezier(0.215, 0.610, 0.355, 1.000);

.fade {
  &-enter-active {
    transition: all .3s ease;
  }

  &-enter {
    opacity: 0;
  }

  &-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  &-leave-to {
    opacity: 0;
  }
}

.fade-img {
  &-enter-active {
    position: fixed;
    z-index: 100002;
    top: 0;
    left: 0;
    transition: opacity 600ms $Power2EaseOut;
  }

  &-enter {
    opacity: 0;
  }

  &-enter-to {
    opacity: 1;
  }

  &-leave-active {
    z-index: 100001;
    transition: opacity 600ms $Power2EaseOut;
  }

  &-leave {
    opacity: 1;
  }

  &-leave-to {
    opacity: 1;
  }

}

.loader {
  z-index: 100002;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 59vh;
}

.decorator {
  @apply bg-primary;

  z-index: 100001;
  position: fixed;
  width: 124px;
  height: 21px;
  top: 0;
  right: 0;
}

.skip {
  z-index: 100001;
  position: fixed;
  top: 7vh;
  right: $padding;
}

.step-image {
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 59vh;
}

.step-content {
  z-index: 100001;
  position: fixed;
  bottom: $padding;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - (#{$padding} * 2));
  transform: translateX(-50%);

  h1 {
    font-family: 'Chillax';
    line-height: 1em;
  }

  .bordered-button {
    border-color: #BEBEBE;
  }
}

.lines {
  z-index: 100001;
  position: fixed;
  top: 0;
  left: 0;
  height: 61vh;
}

.separator {
  z-index: 100000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 65vh;
}

.last-step-actions {
  @apply mt-6 w-full;

  button {
    @apply mb-4;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
