<template>
  <button
    :type="submit ? 'submit' : 'button'"
    :disabled="disabled"
    class="p-sm-full text-14 rounded-[1.2rem] flex items-center justify-center font-semibold leading-none relative"
    :class="{
    'pointer-events-none' : loading,
    'opacity-50': disabled,
    'btn-outline-dark' : outline,
    'btn-error text-white' : !outline && color === 'error',
    'btn-secondary text-white' : !outline && color === 'secondary',
    'bg-black text-white' : !outline && color === 'black',
    'bg-warning text-white' : !outline && color === 'warning',
    'btn-primary text-white' : !outline && color === 'primary'}">
    <slot v-if="!loading"></slot>
    <span v-else>
      &nbsp
      <span class="absolute top-0 left-0 w-full h-full" >
        <Loading />
      </span>
    </span>

  </button>
</template>

<script>
import Loading from "@/components/Loading.vue"

export default {
  components: {
    Loading
  },
  props: {
    color: {
      type: String,
      default: 'secondary'
    },  // primary or secondary
    outline: Boolean,
    loading: Boolean,
    warning: Boolean,
    submit: Boolean,
    disabled: Boolean,
  },
}
</script>
