<template>
  <div
    :data-id="file.key"
    class="relative cursor-move mb-10">
    <div v-if="highlight" class="absolute z-10 -top-4 flex justify-center w-full left-0">
      <span
        class="bg-primary rounded-full text-white text-12 flex items-center justify-center px-4 py-1"
        v-html="highlight"/>
    </div>

    <span
      class="absolute pt-4 z-10 rounded-lg w-full h-full bg-red-500 bg-opacity-70 bg-blend-color-burn text-white text-14 flex items-center text-center justify-center"
      :class="{'text-54': serverID === 'error'}"
      v-if="['errorSize', 'error', 'errorNetwork'].includes(serverID)">
      <template v-if="serverID === 'errorSize'">
        Max: 10 Mo
      </template>
      <template v-if="serverID === 'error'">
        !
      </template>
      <template v-if="serverID === 'errorNetwork'">
        {{ $t('fileExplorer.upload.error.network')}}
      </template>
    </span>

    <div class="z-10 absolute right-4 top-6">
      <IconButton icon="delete"
                  :loading="serverID === 'loading' && loader === true"
                  class="p-2"
                  border
                  small
                  no-padding
                  plain
                  @click.native="$emit('delete',file, filepondID)"/>
    </div>

    <div ref="previewContainer" class="h-[13rem] sm:h-[22rem] w-full overflow-hidden rounded-lg bg-white border flex justify-center items-center"
         :class="{'border-outline-light': !highlight, 'border-primary border-[5px]': highlight}">
      <canvas ref="canvas" class="h-full rounded-lg overflow-hidden preview w-full" height="240"
              width="190"/>

    </div>



    <div v-if="!['errorSize', 'error', 'errorNetwork'].includes(serverID)"
         class="z-10 absolute right-4 bottom-4">
      <IconButton icon="rotate"
                  :loading="serverID === 'loading' && loader === true"
                  class="p-2"
                  border
                  small
                  no-padding
                  plain
                  @click.native="rotate"/>
    </div>

  </div>
</template>

<script>

import Delete from "~/assets/svg/delete.svg?inline";
import imgProxyURL from "assets/js/utils/imgProxy";
import IconButton from "@/components/buttons/IconButton.vue"
import {NETWORK_ERROR} from "@/mixins/filepond";

export default  {
  components: {IconButton, Delete},
  props: {
    loader: Boolean,
    file: [Object],
    processFunc : Function,
    label: {
      type: String,
      default: '&nbsp'
    },
    highlight: String,
  },
  data: () => ({
    preview: '',
    width: null,
    height: null,
    filepondID: null,
    serverID: 'loading',
    fileObject: null,
    reader: null,
    imageRotation:  {
      degrees: 0
    },
    canvas: null
  }),
  async mounted() {

    await new Promise(r => setTimeout(r, 100));

    this.serverID = 'loading';

    if (this.file) {
      this.imageRotation.degrees = this.file.rotation
      // we convert heic to jpg
      if (this.file.src && this.file.src.type === 'image/heic') {
        this.file.src = await this.readHeic(this.file.src);

        if (!this.file.src) {
          this.$emit('error');
          this.serverID = 'error';
        }
      }

      if (this.file.type === 'url') {

        this.fileObject = await this.getImageFromUrl(process.env.imgProxy + imgProxyURL(this.file.src, 512, 512, 'jpg', 'fit'));
        //this.fileObject = await this.loadFile(blob);
        this.serverID = this.file.src;
      } else {
        this.fileObject = await this.loadFile(this.file.src);
      }

      if (this.fileObject) {
        this.updateCanvas()
        // url file doesn't neet to be uploaded (product update or picture update)
        if (this.file.type !== 'url')
        {

          const size = this.file.src.size / (1024 * 1024);
          const isSizeOK = size <= 10;

          if (!isSizeOK) {

            this.serverID = 'errorSize';
            this.$emit('error');

          } else {

            if (this.file.src) {
              this.upload();
            } else {
              this.serverID = 'error';
              this.$emit('error');
            }
          }
        } else {
          this.serverID = this.file.src;
        }
      }
    }
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.preview);

    this.preview = null;
    this.filepondID = null;
    this.serverID = null;
    this.reader = null;
  },
  watch: {
    preview(value) {
      this.$emit('preview', value)
    }
  },
  methods: {
    async readHeic(file) {
      const heic2any = await import('heic2any');
      const image = heic2any.default({blob: file, toType: "image/jpeg", quality: 0.9});
      return image;
    },
    async upload(convertedFile) {

      try {
        //const uploaded = await this.$store.dispatch('upload/processFile', convertedFile ? convertedFile : this.file.src);
        const uploaded = await this.processFunc(convertedFile ? convertedFile : this.file.src)//await this.$filepond.processFile(convertedFile ? convertedFile : this.file.src);

        // pour mocker l'erreur d'upload
        //let randomZeroOrOne = Math.round(Math.random());
        //if (uploaded.status === 5 && randomZeroOrOne) {
        if (uploaded.status === 5) {

          this.serverID = uploaded.serverId;
          this.filepondID = uploaded.id;
          //this.$emit('uploaded', {serverID: this.serverID, rotation: this.imageRotation.degrees});

        } else {
          this.serverID = 'error';
        }
      } catch (e) {
        if (e.error?.body === NETWORK_ERROR) {
          this.serverID = NETWORK_ERROR;
        } else {
          this.serverID = 'error';
        }
        this.$emit('error');
      }

      this.$emit('uploaded', {serverID: this.serverID, rotation: this.imageRotation.degrees});

    },

    rotate() {
      if (parseInt(this.imageRotation.degrees) === 0) {
        this.imageRotation.degrees = 270
      } else {
        this.imageRotation.degrees -= 90
      }

      this.updateCanvas()

      this.$emit('rotate', this.file, this.filepondID, this.imageRotation.degrees)
    },
    loadFile(data) {
      return new Promise(resolve => {
        this.reader = new FileReader();

        this.reader.addEventListener('load', () => {

          const img = new Image();
          img.onload = () => {
            resolve(img);
          }
          img.src = this.reader.result;


        }, false);

        this.reader.addEventListener('error', () => {
          resolve(null);
        });
        this.reader.readAsDataURL(data);
      });
    },
    getImageFromUrl(url){
      return new Promise(resolve => {
        const img = new Image();
        img.onload = () => {
          resolve(img);
        }
        img.src = url;
      });
    },
    updateCanvas() {
      const image = this.fileObject
      let canvas = this.$refs.canvas;
      if (canvas) {
        let ctx = canvas.getContext("2d");
        function drawBestFit(angle){
          const cw = canvas.width / 2;  // half canvas width and height
          const ch = canvas.height / 2;

          const iw = image.width / 2;  // half image width and height
          const ih = image.height / 2;
          // get the length C-B
          const dist = Math.sqrt(Math.pow(cw,2) + Math.pow(ch,2));
          // get the angle A
          const diagAngle = Math.asin(ch/dist);

          // Do the symmetry on the angle
          let a1 = ((angle % (Math.PI *2))+ Math.PI*4) % (Math.PI * 2);

          if(a1 > Math.PI){
            a1 -= Math.PI;
          }
          if(a1 > Math.PI/2 && a1 <= Math.PI){
            a1 = (Math.PI/2) - (a1-(Math.PI/2));
          }
          // get angles A1, A2
          const ang1 = Math.PI/2 - diagAngle - Math.abs(a1);
          const ang2 = Math.abs(diagAngle - Math.abs(a1));
          // get lenghts C-E and C-F
          const dist1 = Math.cos(ang1) * dist;
          const dist2 = Math.cos(ang2) * dist;
          // get the max scale
          const scale = Math.max(dist2/(iw),dist1/(ih));
          // create the transform
          const dx = Math.cos(angle) * scale;
          const dy = Math.sin(angle) * scale;
          ctx.setTransform(dx, dy, -dy, dx, cw, ch);
          ctx.drawImage(image, -iw, - ih);

          // reset the transform
          ctx.setTransform(1, 0, 0, 1, 0, 0);

        }
        drawBestFit(this.imageRotation.degrees * (Math.PI/180))
      }

    }
  },
}
</script>

<style>

</style>
