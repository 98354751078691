<template>
  <div class="flex flex-col items-center bg-white relative z-10">
    <div class="flex flex-col items-center justify-center px-12 py-20 text-center">
      <Icon v-if="icon" :icon="icon" class="h-20 w-20"/>
      <span class="font-bold text-22 mt-4 font-heading whitespace-pre-wrap capitalize-first" v-html="title" />
      <span class=" text-14 mt-4 font-heading whitespace-pre-wrap" v-html="description" />
    </div>
    <Icon v-if="bottomIcon" :icon="bottomIcon" class="min-h-[6rem] min-w-[6rem]"/>
  </div>


</template>

<script>
import Icon from "@/components/global/Icon.vue";

export default {
  name: "EmptyResults",
  components: {
    Icon,
  },
  props: {
    icon: String,
    title: String,
    description: String,
    bottomIcon: String
  }
}
</script>

<style scoped>

</style>
