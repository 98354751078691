<template>
  <div class="swiper-slide cursor-pointer" :class="{'h-auto': heightAuto}" style="-webkit-transform: translate3d(0, 0, 0);">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    heightAuto: Boolean,
  },
  mounted() {
    this.$emit('ready');
  }
}
</script>
