<template>
  <div class="h-full flex flex-col">
    <PictureBg :src="image"
               :sizes="['1920:1080']"
               class="flex flex-col items-center justify-center h-[17rem] w-full px-8">
        <h1 class="font-extrabold text-[2.6rem] whitespace-pre-wrap text-center leading-[3.5rem]" v-html="title"/>
        <InclinedText :label="catchphrase" size="sm"/>
    </PictureBg>
    <div class="flex flex-col items-center flex-1 justify-around">
      <div v-for="(content, index) in contents"
           class="flex flex-col items-center"
           :key="index">
       <TextRow description-size="text-14 font-medium" text-center :label="content.title" :description="content.description" />
      </div>
    </div>


  </div>
</template>

<script>
import InclinedText from "@/components/landing/InclinedText.vue"
import TextRow from "@/components/global/TextRow.vue"
import PictureBg from "~/components/PictureBg.vue"
export default {
  name: "TitleSlide",
  components: {PictureBg, TextRow, InclinedText},
  props: {
    title: String,
    image: String,
    catchphrase: String,
    contents: Array
  }
}
</script>

<style scoped>

</style>
