<template>
  <button @click="back" class="flex justify-center items-center text-black bg-white border border-gray-400 rounded-full" :class="[{'shadow-btn': shadow}, big ? 'w-22 h-22' : 'w-18 h-18']">
    <DeleteIcon v-if="icon === 'cross-bg'" class="w-8 h-8"/>
    <ArrowLeftIcon v-else-if="icon === 'arrow'" class="w-6 h-6"/>
    <DeleteIcon v-else-if="icon === 'cross'" class="w-8 h-8"/>
  </button>
</template>

<script>
import DeleteIcon from "~/assets/svg/delete.svg?inline"
import ArrowLeftIcon from "~/assets/svg/arrow-left.svg?inline"
import {mapGetters} from "vuex";

export default {
  components: {
    DeleteIcon,
    ArrowLeftIcon
  },
  props: {
    forceHome : {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'arrow',
    },
    shadow: {
      type: Boolean,
      default: true
    },
    big: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      history: 'getHistory',
      backHome: 'getBackHome'
    })
  },
  data() {
    return {
      path: '/',
    };
  },
  methods: {
    async back() {
      if (process.client) {
        if(this.backHome || this.forceHome) {
          await this.$router.replace(this.localePath({name: 'index'}));
        } else {
          await this.$router.back();
        }
      }
    }
  }
};
</script>
