<template>
  <div class="flex"
       :class="{[small ? 'p-4' : 'p-8']: !noPadding}">
    <span @click.prevent.stop="onSwitch"
          class="flex-shrink-0 ml-auto cursor-pointer relative bg-primary h-12 w-20 block rounded-full  transition-all"
          :class="{'bg-primary' : value, ' bg-gray-400' : !value}">
      <span
        class="shadow transition-all bg-white absolute h-12 w-12 rounded-full border-2 top-0"
        :class="{'left-0 border-gray-400' : !value, 'left-full -translate-x-full border-primary' : value}"/>
    </span>
    <Loader v-if="loading" class="animate-spin pointer-events-none w-6 h-6 ml-4"/>
  </div>
</template>

<script>
import Loader from "~/assets/svg/loader.svg?inline";

export default {
  name: "SwitchButton",
  components : {
    Loader
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    loading : {
      type: Boolean
    },
    small: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    onSwitch(e) {
      e.stopPropagation();
      this.$emit('change', !this.value)
      //this.value = !this.value
    }
  }
}
</script>

<style scoped>

</style>
