<template>
  <nav class="hidden lg:block border-t border-surface-light ">
    <div class="relative relative flex flex-col" v-click-outside="close">
      <div class="flex items-center container mx-auto gap-16 px-sm-x ">
        <button
          @click="onClickMainNav('sports')"
          :class="{'border-primary text-primary' : current === 'sports', 'border-transparent' : current !== 'sports'}"
          class="text-14 font-bold  uppercase border-b-2 leading-[57px] height-[57px] hover:text-primary transition-colors">
          sports
        </button>
        <nuxt-link
          :to="localePath({path : `/${lifestyle.url}`})"
          event=""
          v-if="lifestyle"
          @click.native.prevent="onClickMainNav('lifestyle', lifestyle, lifestyle.children[0])"
          class="text-14 font-bold  uppercase border-b-2 leading-[57px] height-[57px] hover:text-primary transition-colors"
          :class="{'border-primary text-primary' : current === 'lifestyle', ' border-transparent' : current !== 'lifestyle'}">
          {{lifestyle.name}}
        </nuxt-link>
        <span class="h-10 w-px bg-surface-medium bg-opacity-30 block"/>
        <nuxt-link v-for="gender in genders"
                   :key="`univers-${gender?.id}`"
                   :to="localePath({path : `/${gender.url}`})"
                   event=""
                   @click.native.prevent="onClickMainNav(`univers-${gender?.id}`, gender, gender.children[0])"
                   class="text-14 font-bold uppercase border-b-2 leading-[57px] height-[57px] hover:text-primary transition-colors"
                   :class="{'border-primary text-primary' : selectedUniverse && selectedUniverse?.id === gender?.id, 'border-transparent' : !selectedUniverse || (selectedUniverse && selectedUniverse?.id !== gender?.id)}">
          {{ gender.name }}
        </nuxt-link>
        <span class="h-10 w-px block bg-surface-medium bg-opacity-30"/>
        <button
          class="text-14 font-bold uppercase border-b-2 leading-[57px] height-[57px] hover:text-primary transition-colors"
          :class="{'border-primary text-primary' : current === 'brands', 'border-transparent' : current !== 'brands'}"
          @click="onClickMainNav('brands')">
          {{$t('header.brands')}}
        </button>
        <button
          @click="onSell"
          class="btn-primary ml-auto ml-4 min-w-[144px] my-2">
          {{$t('header.sell')}}
        </button>
      </div>
      <div class="w-full flex bg-light-blue">
        <div class="container mx-auto flex gap-16 p-sm-full">
          <nuxt-link v-for="univer in univers"
                     :to="localePath({path : `/${univer.url}`})"
                     event=""
                     :key="`univers-${univer?.id}`"
                     @click.native.prevent="onClickMainNav(`univers-${univer?.id}`, univer, univer.children[0])"
                     class=" text-14 font-bold capitalize whitespace-nowrap hover:text-primary transition-colors"
                     :class="{'text-primary' : selectedUniverse && selectedUniverse?.id  === univer?.id}">
            {{ univer.name.toLowerCase() }}
          </nuxt-link>
        </div>
      </div>
      <!-- NAVIGATION -->
      <transition name="Navigation">
        <div v-show="current"
             class="absolute overflow-hidden w-full top-full bg-background-light">
          <transition v-for="universe in [...univers, ...genders, lifestyle]"
                      :key="universe?.id"
                      name="NavigationSlide">
            <UniverseNavigation :key="universe?.id"
                                v-show="selectedUniverse?.id === universe?.id"
                                @navigatedTo="pushNavigatedToEvent"
                                @selectSubUniverse="selectSubUnivers"
                                :sub-universe-selected="selectedSubUniverse"
                                :universe="universe"/>
          </transition>
          <!-- SPORTS -->
          <transition name="Navigation">
            <SportsNavigation v-show="current === 'sports'"
                              @navigatedTo="pushNavigatedToEvent"/>
          </transition>
          <!-- BRANDS -->
          <transition name="Navigation">
            <BrandsNavigation v-show="current === 'brands'" />
          </transition>
          <!-- NAV FOOTER-->
          <div class="w-full bg-primary">
            <div
              class="container mx-auto text-14 text-white text-center p-sm-full">
                {{$t('navigation.bottom')}}
            </div>
          </div>
        </div>
      </transition>

    </div>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";
import {navigatedToParams, NAVIGATION_LOCATIONS} from "assets/js/gtm-events";
import {brandLink} from "assets/js/utils/string";
import BrandsNavigation from "@/components/header/BrandsNavigation.vue"
import SportsNavigation from "@/components/header/SportsNavigation.vue"
import UniverseNavigation from "@/components/header/UniverseNavigation.vue"
import {Storage} from "@capacitor/storage";
import {STORAGE_KEYS} from "assets/js/constants/constant";
import {isOneDayAgo} from "assets/js/utils/date";

export default {
  components: {UniverseNavigation, SportsNavigation, BrandsNavigation},
  data() {
    return {
      current: null,
      selectedUniverse: null,
      selectedSubUniverse: null,
    }
  },
  computed: {
    ...mapGetters({
      nav: 'nav/getNav',
    }),
    path() {
      return process.env.staticAssets + '/site';
    },
    lifestyle() {
      return this.nav?.find(item => item.isUniverse === 1 && item.id === 220);
    },
    navigation() {
      return this.nav?.filter(item => item.isUniverse === 1).map(item => item.name);
    },
    univers() {
      return this.nav?.filter(item => item.isUniverse === 1 && item.id !== 220)
    },
    genders() {
      return this.nav?.filter(item => item.isUniverse === 0)
    },
  },
  watch: {
    '$route'() {
      this.close();
    }
  },
  methods: {
    brandLink,
    async pushNavigatedToEvent(navItem) {
      this.$gtm.push(navigatedToParams(
        navItem.name,
        NAVIGATION_LOCATIONS.header,
        await this.$store.dispatch('nav/searchByID', navItem?.id),//navItem.parentId ||
      ));
    },
    onSell() {
      if (this.$route.name.includes('product-sell')) {
        this.$router.push(this.localePath({name: 'product-add'}))
      } else {
        Storage.get({key: STORAGE_KEYS.howToSellSeenDate}).then(async ({value: howToSellSeenDate}) => {
          if (howToSellSeenDate && isOneDayAgo(new Date(howToSellSeenDate))) {
            await this.$router.push(this.localePath({
              name: 'product-add'
            }))
          } else {
            await this.$router.push(this.localePath({name: 'product-sell'}))
          }
        })
      }
    },
    close() {
      this.current = null;
      this.selectedUniverse = null;
      this.selectedSubUniverse = null;
    },
    selectSubUnivers(univers) {
      if (this.selectedSubUniverse === univers) {
        this.$router.push(this.localePath({path : `/${univers.url}`}))
      } else {
        this.selectedSubUniverse = univers
      }
    },
    onClickMainNav(type, universe = null, subUniverse = null) {
      if (this.current !== type) {
        this.current = type
      } else {
        this.current = ''
      }
      this.selectedUniverse = universe
      this.selectedSubUniverse = subUniverse
    }
  }
}
</script>
