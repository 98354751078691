<template>
  <div class="fixed top-0 z-[200] notch-pt left-0 w-full">
    <transition-group name="toast" tag="div" class="absolute flex items-center flex-col w-full">
      <div v-for="toast in toast"
           :key="toast.id"
           class="py-4 px-20 rounded-md m-2"
           :class="{'bg-red-500' : !toast.status || toast.status === 0, 'bg-primary' : toast.status === 1}">
        <span class="text-white text-16" v-html="toast.content"></span>
      </div>
    </transition-group>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      toast: 'getToasts'
    })
  },
  data() {
    return {
      timeout : -1
    }
  },
  methods: {
    kill() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$store.commit('clearToast');
      },2000);
    }
  },
  mounted() {
    this.kill();
  },
  watch : {
    toast(value) {
      if(value && Array.isArray(value) && value.length) {
        this.kill();
      }
    }
  }
}
</script>
