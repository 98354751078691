<template>
  <div class="flex flex-col"
       :class="{'overflow-hidden': truncate}">
    <div class="flex" :class="{'justify-center': textCenter, 'justify-between': !textCenter}">
      <component :is="titleTag" class="font-heading font-bold lea"
            :class="{
             'text-center': textCenter,
             'leading-10': badges && badges.length,
             [titleSize]: titleSize,
             [labelColor]: labelColor
      }">
        <span class="whitespace-pre-line" v-html="label"/>
        <Icon @click.native="$emit('clickHint')" class="text-primary inline-block ml-2" v-if="hint" icon="hint" />
        <Badge v-for="(badge, index) in badges"
               :key="index"
               small
               class="ml-2 !inline-flex items-center h-8 align-middle"
               :rounded="false"
               :highlight="badge.highlight"
               :type="badge.type"
               :label="badge.label"/>

      </component>

      <span v-if="rightLabel" class="font-heading font-bold"
            v-html="rightLabel"
            :class="{
              [titleSize]: titleSize,
              [labelColor]: labelColor && !rightLabelColor,
              [rightLabelColor]: rightLabelColor
            }"/>
      <button v-if="action"
              type="button"
              @click="$emit('action')"
              class="font-heading text-surface-medium underline flex items-center text-14">{{ action }}</button>
    </div>

    <p class="text-surface-medium"
       :class="{'whitespace-pre-wrap': !truncate, 'truncate': truncate, 'text-center': textCenter, [descriptionSize]: descriptionSize, [descriptionColor]: descriptionColor,}"
       v-if="description">{{ description }}<Icon @click.native="$emit('clickSecondaryHint')" class="ml-2 text-surface-medium inline-block float-right" v-if="secondaryHint" icon="hint" /></p>
  </div>
</template>

<script>
import Icon from "@/components/global/Icon"
import Badge from "@/components/global/Badge"
export default {
  name: "TextRow",
  components: {Badge, Icon},
  props: {
    label: String,
    rightLabel: String,
    hint: Boolean,
    textCenter: Boolean,
    description: String,
    descriptionSize: {
      type: String,
      default: 'text-13'
    },
    titleSize: {
      type: String,
      default: 'text-16'
    },
    titleTag: {
      type: String,
      default: 'span'
    },
    badges: Array,
    largeDescription: Boolean,
    truncate: Boolean,
    labelColor: String,
    rightLabelColor: String,
    descriptionColor: String,
    action: String,
    actionArrow: Boolean,
    secondaryHint: Boolean
  }
}
</script>

<style scoped>

</style>
