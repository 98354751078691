<template>
  <span class="flex h-4 w-4">
    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"/>
    <span class="relative inline-flex rounded-full h-4 w-4 bg-primary border border-white"></span>
  </span>
</template>

<script>
export default {
  name: "UnreadChip"
}
</script>

<style scoped>

</style>
