<template>
  <footer class="bg-secondary pt-32">
    <div class="content flex flex-col items-center pb-20">
      <img width="200" height="42" :src="logo" class="w-[150px] mb-6 text-primary" alt="Everide logo"/>
      <p class="text-18 text-white text-center font-semibold font-heading" v-html="$t('footer.manifesto')"/>
      <div class="hidden mt-14 lg:flex gap-14">
        <a
          rel="noreferrer"
          href="https://apps.apple.com/fr/app/everide/id1587901394"
          target="_blank"
          @click="pushNavigatedToEvent('App Store - Apple')"
        >
          <img width="143" height="45" alt="App Store - Apple" :src="appStore"/>
        </a>
        <a
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.everide.app&gl=FR"
          target="_blank"
          @click="pushNavigatedToEvent('Google play store')"
        >
          <img width="153" height="45" alt="Google play store" :src="playStore"/>
        </a>
      </div>
    </div>

    <div
      class="content py-16 flex flex-col sm:flex-row justify-between items-center text-white text-14 border-t border-surface-light"
    >
      <a
        rel="noreferrer"
        aria-label="kilian jornet foundation"
        class="block w-full"
        href="https://www.kilianjornetfoundation.org/fr/"
        target="_blank"
        @click="pushNavigatedToEvent('Kilian Jornet foundation')"
      >
        <Picture :noBg="true" resizing="fit-contain" class="h-[50px]" :sizes="['560:168']"
                 :src="path + '/footer/partners/kjf_logo_white.png'"/>
      </a>
      <a
        rel="noreferrer"
        aria-label="protect our winters"
        class="block w-full"
        href="https://protectourwinters.fr/"
        target="_blank"
        @click="pushNavigatedToEvent('Protect our winters')"
      >
        <Picture noBg resizing="fit-contain" class="h-[60px]" :sizes="['540:120']"
                 :src="path + '/footer/partners/pow_logo_white.png?v2'"/>
      </a>
      <a
        rel="noreferrer"
        aria-label="surfrider"
        class="block w-full"
        href="https://surfrider.eu/"
        target="_blank"
        @click="pushNavigatedToEvent('Surfrider')"
      >
        <Picture noBg resizing="fit-contain" class="h-[45px]" :sizes="['540:180']"
                 :src="path + '/footer/partners/logo_srf.png'"/>
      </a>
      <a
        rel="noreferrer"
        aria-label="outdoor sports valley"
        class="block w-full"
        href="https://www.outdoorsportsvalley.org/"
        target="_blank"
        @click="pushNavigatedToEvent('Outdoor sports valley')"
      >
        <Picture noBg resizing="fit-contain" class="h-[105px]" :sizes="['540:180']"
                 :src="path + '/footer/partners/logo_osv_white.png'"/>
      </a>
    </div>

    <nav class="content grid grid-cols-12 gap-4 py-16 border-t border-surface-light px-4">
      <div class="col-span-3 flex flex-col">
        <span class="font-bold text-16 text-white mb-6">
          {{ $t('footer.about.title') }}
        </span>
        <!-- TODO: Add link -->
        <span class="py-3 text-14 text-primary">
          {{ $t('footer.about.manifesto') }}
        </span>
        <nuxt-link
          :to="localePath({name : 'page-cgv'})"
          class="py-3 text-14 text-primary"
          @click.native="pushNavigatedToEvent('page-cgv')"
        >
          {{ $t('footer.about.cgv') }}
        </nuxt-link>
        <nuxt-link
          :to="localePath({name : 'page-cookies'})"
          class="py-3 text-14 text-primary"
          @click.native="pushNavigatedToEvent('page-cookies')"
        >
          {{ $t('footer.about.cookie') }}
        </nuxt-link>
        <nuxt-link
          :to="localePath({name : 'page-legals'})"
          class="py-3 text-14 text-primary"
          @click.native="pushNavigatedToEvent('page-legals')"
        >
          {{ $t('footer.about.legals') }}
        </nuxt-link>
        <nuxt-link
          :to="localePath({name : 'page-guarantees'})"
          class="py-3 text-14 text-primary"
          @click.native="pushNavigatedToEvent('page-guarantees')"
        >
          {{ $t('footer.about.guarantees') }}
        </nuxt-link>
      </div>

      <div class="col-span-3 flex flex-col">
        <span class="font-bold text-16 text-white mb-6">{{ $t('footer.help.title') }}</span>
        <nuxt-link
          :to="localePath({name : 'page-how-to'})"
          class="py-3 text-14 text-primary"
          @click.native="pushNavigatedToEvent('page-how-to')"
        >
          {{ $t('footer.help.howItWorks') }}
        </nuxt-link>
        <!--nuxt-link :to="localePath({name : 'page-sell'})" class="py-3 text-14 text-primary">{{
            $t('footer.help.sell')
          }}
        </nuxt-link>
        <nuxt-link :to="localePath({name : 'page-buy'})" class="py-3 text-14 text-primary">{{
            $t('footer.help.buy')
          }}
        </nuxt-link-->
        <nuxt-link
          :to="localePath({name : 'content-page', params : {page : config?.pages?.account[0].slug}})"
          class="py-3 text-14 text-primary"
          @click.native="pushNavigatedToEvent('page-assistance')"
        >
          {{ $t('footer.help.faq') }}
        </nuxt-link>

        <nuxt-link
          :to="localePath({name : 'pro'})"
          class="py-3 text-14 text-primary"
          @click.native="pushNavigatedToEvent('pro')"
        >
          {{ $t('footer.help.pro') }}
        </nuxt-link>

        <client-only>
          <overlay-form
            :force-height="false"
            class="text-left"
            type="popinV2"
            component="contact"
            :noStyle="true"
            action="signal"
            :data="{type : 'contact'}"
          >
          <button class="py-3 text-14 text-primary" @click="pushNavigatedToEvent('contact')">
            {{ $t('contact.cta') }}
          </button>
        </overlay-form>
        </client-only>

      </div>
      <div class="col-span-3 flex flex-col gap-4">
        <span class="font-bold text-16 text-white mb-6">{{ $t('footer.social.title') }}</span>
        <!--span class="py-3 text-14 text-primary">
          {{ $t('footer.social.press') }}
        </span-->
        <div class="flex gap-4">
          <a
            rel="noreferrer"
            href="https://www.facebook.com/everideapp/"
            target="_blank"
            class="block w-16"
            @click="pushNavigatedToEvent('Facebook')"
          >
            <Facebook class="text-primary"/>
          </a>
          <a
            rel="noreferrer"
            href="https://www.instagram.com/everide.app/"
            target="_blank"
            class="block w-16"
            @click="pushNavigatedToEvent('Instagram')"
          >
            <Instagram class="text-primary"/>
          </a>
        </div>
      </div>
    </nav>

    <div class="content flex justify-between items-center text-white text-14 border-t border-surface-light">
      <div>
        © Everide {{ new Date().getFullYear() }}
        <small class="text-secondary">{{ version }}</small>
      </div>
      <div class="flex items-center">
        <p v-html="$t('footer.payments')"/>
        <Visa class="w-40"/>
        <Mastercard class="-ml-20 w-40 -mr-12"/>
      </div>
    </div>
  </footer>
</template>

<script>

import Facebook from "~/assets/svg/footer/facebook.svg?inline";
import Instagram from "~/assets/svg/footer/instagram.svg?inline";
import Logo from "~/assets/svg/logo-primary.svg"
import Visa from "~/assets/svg/footer/visa.svg?inline";
import Mastercard from "~/assets/svg/footer/mastercard.svg?inline";
import OverlayForm from "@/components/form/OverlayForm"
import { navigatedToParams, NAVIGATION_LOCATIONS } from "../../assets/js/gtm-events";
import {mapGetters} from "vuex";
import Picture from "@/components/Picture.vue"

export default {
  components: {Picture, Facebook, Instagram, Visa, Mastercard, OverlayForm},
  data: () => ({
    path: process.env.staticAssets + '/site',
    logo: Logo,
    version: process.env.buildVersion || 'VERSION',
    playStore: process.env.staticAssets + '/site/playstore.svg',
    appStore: process.env.staticAssets + '/site/appstore.svg',
  }),
  methods: {
    pushNavigatedToEvent(title) {
      this.$gtm.push(navigatedToParams(title, NAVIGATION_LOCATIONS.footer, []))
    },
  },
  computed: {
    ...mapGetters({
      config : "config/getConfig"
    }),
  }
}
</script>
