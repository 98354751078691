<template>
  <div>
    <span class="bg-primary text-white font-heading font-extrabold uppercase rounded px-2 ml-2 font-bold rotate-[-5deg] inline-block"
          :class="{'inclined-shadow': shadow, ['text-' + fontSize ]: true}">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "InclinedText",
  props: {
    label: String,
    shadow: Boolean,
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    fontSize() {
      switch (this.size) {
        case 'lg':
          return 20
        case 'sm':
          return 16
        default:
          return 18

      }
    }
  }
}
</script>

<style scoped>
 .inclined-shadow {
   bbox-shadow: rgb(0 0 0 / 35%) 3px 3px 3px 0px;
   margin-top:10px;
 }
</style>
