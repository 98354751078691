<template>
    <button type="button" class="w-full" @click.stop.prevent="onClick">
      <slot :response="result" />
    </button>
</template>

<script>
import Arrow from "~/assets/svg/arrow.svg?inline";

export default {
  components: {Arrow},
  props: {
    forceHeight: [Boolean, String],
    width : String,
    component: String,
    type: {
      type: String,
      default: 'default'
    },
    clickOutside: {
      type : Boolean,
      default : true
    },
    data: [Object, String],
  },
  data () {
    return {
      response : {}
    }
  },
  computed : {
    result() {
      return this.response;
    }
  },
  mounted() {
    this.$root.$on(`${this._uid}-response`,this.onResponse);
  },
  beforeDestroy() {
    this.$root.$off(`${this._uid}-response`,this.onResponse);
  },
  methods: {
    onResponse(data) {
      this.$emit('response', data);
    },
    onClick() {
      this.$store.commit('overlayForm/component', this.component);
      this.$store.commit('overlayForm/type', this.type);
      this.$store.commit('overlayForm/data', this.data);
      this.$store.commit('overlayForm/busId', this._uid);
      this.$store.commit('overlayForm/size', this.width);
      this.$store.commit('overlayForm/forceHeight', this.forceHeight);
      this.$store.commit('overlayForm/clickOutside', this.clickOutside);
    }
  },

};
</script>
