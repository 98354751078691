<template>
  <PictureBg :src="cover"
             :sizes="['1080:1920']"
             class="fixed h-full flex flex-col justify-between h-full w-full pt-safe pb-safe">
    <div class="py-16 px-20 relative">
      <Icon icon="quote" class="text-gray-300 opacity-75 absolute top-4 -left-4 w-22 h-22"/>
      <p class="text-center text-16 text-secondary font-medium relative z-[2]"
         v-html="$t('introductionSteps.first.content')">
      </p>
      <Icon icon="quote" class="text-gray-300 opacity-75 absolute bottom-20 -right-4 w-22 h-22 rotate-180"/>
      <Icon icon="signature_KJ" class="text-secondary absolute bottom-5 right-32 w-20 h-20"/>
    </div>
    <Button class="uppercase mx-10 text-18 py-6 mb-20"
            @click.native.prevent.stop="$emit('next')"
            color="primary">
      {{ $t('global.next') }}
    </Button>
  </PictureBg>
</template>

<script>
import Icon from "@/components/global/Icon.vue"
import Button from "@/components/buttons/Button.vue"
import PictureBg from "~/components/PictureBg.vue"

export default {
  name: "FirstStep",
  components: {PictureBg, Button, Icon},
  computed: {
    cover() {
      return process.env.staticAssets + '/site/introduction/splashscreen.png'
    },
  }
}
</script>

<style scoped>

</style>
