var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col",class:{'overflow-hidden': _vm.truncate}},[_c('div',{staticClass:"flex",class:{'justify-center': _vm.textCenter, 'justify-between': !_vm.textCenter}},[_c(_vm.titleTag,{tag:"component",staticClass:"font-heading font-bold lea",class:{
           'text-center': _vm.textCenter,
           'leading-10': _vm.badges && _vm.badges.length,
           [_vm.titleSize]: _vm.titleSize,
           [_vm.labelColor]: _vm.labelColor
    }},[_c('span',{staticClass:"whitespace-pre-line",domProps:{"innerHTML":_vm._s(_vm.label)}}),_vm._v(" "),(_vm.hint)?_c('Icon',{staticClass:"text-primary inline-block ml-2",attrs:{"icon":"hint"},nativeOn:{"click":function($event){return _vm.$emit('clickHint')}}}):_vm._e(),_vm._v(" "),_vm._l((_vm.badges),function(badge,index){return _c('Badge',{key:index,staticClass:"ml-2 !inline-flex items-center h-8 align-middle",attrs:{"small":"","rounded":false,"highlight":badge.highlight,"type":badge.type,"label":badge.label}})})],2),_vm._v(" "),(_vm.rightLabel)?_c('span',{staticClass:"font-heading font-bold",class:{
            [_vm.titleSize]: _vm.titleSize,
            [_vm.labelColor]: _vm.labelColor && !_vm.rightLabelColor,
            [_vm.rightLabelColor]: _vm.rightLabelColor
          },domProps:{"innerHTML":_vm._s(_vm.rightLabel)}}):_vm._e(),_vm._v(" "),(_vm.action)?_c('button',{staticClass:"font-heading text-surface-medium underline flex items-center text-14",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('action')}}},[_vm._v(_vm._s(_vm.action))]):_vm._e()],1),_vm._v(" "),(_vm.description)?_c('p',{staticClass:"text-surface-medium",class:{'whitespace-pre-wrap': !_vm.truncate, 'truncate': _vm.truncate, 'text-center': _vm.textCenter, [_vm.descriptionSize]: _vm.descriptionSize, [_vm.descriptionColor]: _vm.descriptionColor,}},[_vm._v(_vm._s(_vm.description)),(_vm.secondaryHint)?_c('Icon',{staticClass:"ml-2 text-surface-medium inline-block float-right",attrs:{"icon":"hint"},nativeOn:{"click":function($event){return _vm.$emit('clickSecondaryHint')}}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }