<template>
  <div class="w-full pt-6 pb-10">
    <div class="container mx-auto grid grid-cols-12 gap-4">
      <div class="col-span-6 grid grid-cols-12">
        <div class="col-span-6 flex flex-col items-start">
          <span class="font-bold text-16 mb-6 leading-none">{{$t('navigation.bestSeller')}}</span>
          <div class="flex flex-col gap-6">
            <BrandButton :brand="best" v-for="best in bestBrands"
                          :key="best.id"/>
          </div>
        </div>
        <div class="col-span-6 flex flex-col items-start">
                    <span
                      class="font-bold text-16 mb-6 leading-none">{{$t('navigation.mostDurable')}}</span>
          <div class="flex flex-col gap-6">
            <BrandButton :brand="durable"
                          v-for="durable in durableBrands"
                          :key="durable.id"/>
          </div>
        </div>
      </div>
      <div class="col-span-6 flex flex-col items-start">
                  <span
                    class="font-bold text-16 mb-4 leading-none">{{$t('navigation.partner')}}</span>
        <p class="text-14 text-surface-medium mb-6" v-html="$t('navigation.partnerDesc')" />
        <div class="w-full grid grid-cols-12 gap-14">
          <nuxt-link :to="localePath({name : 'brand-slug', params : {slug : brandLink(partner.slug, $i18n)}})"
                     class="col-span-6"
                     :key="partner.id"
                     v-for="partner in partnerBrands">
            <Picture :key="partner.partnerImage"
                     class="overflow-hidden rounded-md"
                     :src="partner.partnerImage"
                     :sizes="['460:276']"/>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {brandLink} from "assets/js/utils/string";
import Picture from "@/components/Picture.vue"
import BrandButton from "@/components/buttons/BrandButton.vue"

export default {
  name: "BrandsNavigation",
  components: {Picture, BrandButton},
  computed: {
    ...mapGetters(
      {
        bestBrands: 'brands/getBestBrands',
        durableBrands: 'brands/getDurableBrands',
        partnerBrands: 'brands/getPartnerBrands',
      }
    ),
  },
  async mounted() {
    await this.$store.dispatch('brands/fetchNavBrands');
  },
  methods: {
    brandLink,
  }
}
</script>

<style scoped>

</style>
