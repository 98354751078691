<template>
  <div
    class="relative cursor-pointer text-center flex-1 text-white text-14 rounded-md block flex-shrink-0 block flex flex-col items-center justify-center bg-primary sm:hover:bg-primary-900"
    :class="{'p-10' : label.length}">

    <AddImage v-if="!capture || isApp && !capture || forceIcon && !isApp && !capture"
                class="pointer-events-none block h-14 w-14"
                :class="{'mb-1' : label.length, '' : !label.length}"/>

    <Camera v-if="capture" class="fill-current pointer-events-none block h-14 w-14"
            :class="{'mb-1' : label.length, '' : !label.length}"/>

    <span v-if="label.length" class="block text-18 pointer-events-none uppercase font-bold mt-4" v-html="label" />

    <input class="opacity-0 appearance-none cursor-pointer absolute w-full h-full top-0 left-0" :capture="capture"
           :accept="accept" ref="file" max
           type="file"
           @change="onInput" :multiple="multiple"/>
  </div>
</template>

<script>
import AddImage from "~/assets/svg/add_image.svg?inline";
import Camera from "~/assets/svg/camera.svg?inline";

export default {
  components: {Camera, AddImage},
  props: {
    capture: Boolean,
    label: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    forceIcon: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$refs.file.addEventListener('click', function(e) {
      e.stopPropagation();
    });
  },
  computed: {
    isApp() {
      return process.env.mobileApp;
    },
    accept() {
      return this.capture ? 'image/*' : '.jpg, .png, .jpeg, .heic';
    }
  },
  methods: {
    onInput(e) {
      this.$emit('files', e.currentTarget.files);
    }
  }
}
</script>
