<template>
  <component :is="component"/>
</template>

<script>

export default {
  name: "Icon",
  props: {
    icon: String
  },
  data() {
    return {
      component: null
    }
  },
  async mounted() {
    this.component = await import('@/assets/svg/' + this.icon + '.svg?inline');
  }
}
</script>

<style scoped>

</style>
