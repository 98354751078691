<template>
  <div class="flex text-red-500 mt-2">
    <Advice />
    <ul class="block w-full list-none pl-6 text-left pt-2" >
      <li class="text-brand text-12" v-for="(error, i) in errors" :key="i" v-html="error" />
    </ul>
  </div>

</template>

<script>
import Advice from "~/assets/svg/advice.svg?inline";

export default {
  components: {
    Advice
  },
  props : {
    errors : {
      type : Array,
      default : null
    }
  }
}
</script>
