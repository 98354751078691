var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"p-sm-full text-14 rounded-[1.2rem] flex items-center justify-center font-semibold leading-none relative",class:{
  'pointer-events-none' : _vm.loading,
  'opacity-50': _vm.disabled,
  'btn-outline-dark' : _vm.outline,
  'btn-error text-white' : !_vm.outline && _vm.color === 'error',
  'btn-secondary text-white' : !_vm.outline && _vm.color === 'secondary',
  'bg-black text-white' : !_vm.outline && _vm.color === 'black',
  'bg-warning text-white' : !_vm.outline && _vm.color === 'warning',
  'btn-primary text-white' : !_vm.outline && _vm.color === 'primary'},attrs:{"type":_vm.submit ? 'submit' : 'button',"disabled":_vm.disabled}},[(!_vm.loading)?_vm._t("default"):_c('span',[_vm._v("\n     \n    "),_c('span',{staticClass:"absolute top-0 left-0 w-full h-full"},[_c('Loading')],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }