<template>
  <footer
    class="lg:hidden flex fixed bottom-0 left-0 z-[60] w-full max-w-full bg-white notch-pb text-black border-gray-400 border-t border-solid">
    <!-- Home & Search -->
    <nuxt-link v-for="(n, i) in 2" :key="nav[i].icon"
               exact-active-class="text-primary"
               class="flex flex-col flex-grow-1 items-center justify-start w-full py-4 font-medium"
               :to="nav[i].path" @click.native="onClickLink(i)" >
      <div class="relative h-full w-full flex items-end justify-center">
         <div class="relative">
            <component :owner="true" :is="nav[i].icon" class="w-9"/>
          </div>
      </div>
      <span class="truncate w-full text-12 text-center flex-shrink-0 mt-1">
        {{ $t('footer.navbar.' + nav[i].icon) }}
      </span>
    </nuxt-link>
    <!-- Sell -->
    <button exact-active-class="text-primary" :class="{'text-primary' : active === 2 && $auth.loggedIn}"
            class="flex flex-col flex-grow-1 items-center justify-start w-full py-4 font-medium relative"
            @click="to(2)" type="button">
      <span class="absolute -top-8 w-22 h-22 d-block btn-sell bg-primary">
        <component :owner="true" :is="nav[2].icon"/>
      </span>

      <span class="truncate w-full text-12 text-center flex flex-1 mt-1 flex-col justify-end">
        {{ $t('footer.navbar.' + nav[2].icon) }}
      </span>
    </button>
    <!-- Messages -->
    <button exact-active-class="text-primary" :class="{'text-primary' : active === 3 && $auth.loggedIn}"
            class="flex flex-col flex-grow-1 items-center justify-start w-full py-4 font-medium"
            @click="to(3)" type="button">
      <div class="relative h-full w-full flex items-end justify-center">
         <div class="relative">
            <component :owner="true" :is="nav[3].icon" class="w-9"/>
            <div class="absolute right-0 top-0" v-if="(messageToRead || notificationToRead) && nav[3].icon === 'messages'">
              <UnreadChip/>
            </div>
        </div>
      </div>
      <span class="truncate w-full text-12 text-center flex-shrink-0 mt-1">
        {{ $t('footer.navbar.' + nav[3].icon) }}
      </span>
    </button>
    <!-- Profil -->
    <nuxt-link exact-active-class="text-primary" :class="{'text-primary' : active === 4 && $auth.loggedIn}"
               @click.native="active = 4" :to="nav[4].path"
               class="flex flex-col flex-grow-1 items-center justify-start w-full py-4 font-medium">
      <div class="relative h-full w-full flex items-end justify-center">
         <div class="relative">
            <component :owner="true" :key="$auth.loggedIn ? $auth.user?.image : nav[4].icon" :is="nav[4].icon" class="h-10 w-10"/>
         </div>
      </div>
      <span class="truncate w-full text-12 text-center flex-shrink-0 mt-1">
        {{ $t('footer.navbar.' + nav[4].icon) }}
      </span>
    </nuxt-link>
  </footer>
</template>

<script>
import Home from "~/assets/svg/nav/home.svg?inline";
import Search from "~/assets/svg/nav/search.svg?inline";
import Sell from "~/assets/svg/footer/add.svg?inline";
import Chat from "~/assets/svg/nav/chat.svg?inline";
import Profil from "~/assets/svg/nav/profil.svg?inline";
import ProfilPic from "@/components/ProfilPic"
import {mapGetters} from "vuex";
import UnreadChip from "@/components/conversation/UnreadChip"
import {Storage} from "@capacitor/storage";
import {STORAGE_KEYS} from "assets/js/constants/constant";
import {isOneDayAgo} from "assets/js/utils/date";

export default {
  components: {
    UnreadChip,
    home: Home,
    search: Search,
    sell: Sell,
    messages: Chat,
    login: Profil,
    profil: ProfilPic
  },
  data() {
    return {
      active: 0,
    }
  },
  mounted() {
    this.$el.addEventListener('touchmove', function(e) {

      e.preventDefault();

    }, false);
  },
  computed: {
    ...mapGetters({
      messageToRead: 'messageToRead',
      notificationToRead: 'notificationToRead'
    }),
    nav() {
      return [
        {icon: 'home', path: this.localePath({name: 'index'})},
        {icon: 'search', path: this.localePath({name: 'search'})},
        {icon: 'sell', path: this.localePath({name: 'product-sell'})},
        {icon: 'messages', path: this.localePath({name: 'conversations', query: {tab: 'conversations'}})},
        {
          icon: this.$auth.loggedIn ? 'profil' : 'login',
          path: this.localePath({name: 'profil'})
        }
      ]
    },

  },
  methods: {
    defineSellPath() {

    },
    to(index) {
      this.active = index
      if (index === 2) {
        if (this.$route.name.includes('product-sell')) {
          this.$router.push(this.localePath({name: 'product-add'}))
        } else {
          Storage.get({key: STORAGE_KEYS.howToSellSeenDate}).then(async ({value: howToSellSeenDate}) => {
            if (howToSellSeenDate && isOneDayAgo(new Date(howToSellSeenDate))) {
              await this.$router.push(this.localePath({
                name: 'product-add'
              }))
            } else {
              await this.$router.push(this.localePath(this.nav[index].path))
            }
          })
        }
      } else {
        this.$router.push(this.localePath(this.nav[index].path))
      }
    },
    onClickLink(index) {
      this.$root.$emit('navbar:click', this.nav[index].path)
      if (this.active === index) {
        window.scrollTo({top: 0, behavior: 'smooth'})
      }
      this.active = index
      this.$root.$emit('search::reset')
    }
  }
}
</script>
