<template>
  <div class="fixed h-full  h-full w-full bg-white overscroll-contain flex flex-col pt-safe pb-safe">
    <TopNav ref="topNav"
            :border="false"
            transparent
            :sticky="false"
            class="lg:hidden">
      {{ $t('login.step.login') }}
    </TopNav>
    <div class="flex flex-col h-full flex-1">
      <div class="flex-1" >
        <FullHeightSlider ref="slider"
                          @change="onChangeSlide"
                          :slides="slides" />
      </div>
      <div class="p-10">
        <Button class="whitespace-nowrap flex-1 uppercase text-18 py-6 w-full"
                @click.native="next"
                color="primary">
          {{ isLastSlide ? $t('notifications.popin.cta')  : $t('global.next') }}
        </Button>
        <div class="mt-2 text-center">
          <button class="py-4 underline text-16 font-medium"
                  @click="skip">
            {{ $t('introductionSteps.skip') }}
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/global/Icon.vue"
import Button from "@/components/buttons/Button.vue"
import TopNav from "@/components/global/TopNav.vue"
import FullHeightSlider from "@/components/slider/FullHeightSlider.vue"

export default {
  name: "SecondStep",
  components: {FullHeightSlider, TopNav, Button, Icon},
  data() {
    const slides = [{
      type: 'TitleSlide',
      image: process.env.staticAssets + '/site/pages/sell/slide-1.png',
      title: this.$t('introductionSteps.slide.1.title'),
      catchphrase: this.$t('introductionSteps.slide.1.catchphrase'),
      contents: [
        {
          title: this.$t('introductionSteps.slide.1.contents.1.title'),
          description: this.$t('introductionSteps.slide.1.contents.1.description'),
        }, {
          title: this.$t('introductionSteps.slide.1.contents.2.title'),
          description: this.$t('introductionSteps.slide.1.contents.2.description'),
        }
      ]
    }]
    for (let i = 2; i < 7; i++) {
      slides.push({
        type: 'BigImageSlide',
        title: this.$t('introductionSteps.slide.' + i + '.title'),
        image: process.env.staticAssets + '/site/introduction/slide-' + i + '.png',
        description: this.$t('introductionSteps.slide.' + i + '.description'),
      })
    }
    return {
      slides,
      isLastSlide: false
    }
  },
  computed: {
    cover() {
      return process.env.staticAssets + '/site/splashscreen.png'
    },
  },
  methods: {
    next() {
      const swiper = this.$refs.slider.$refs.swiper.swiper
      if (swiper.activeIndex === this.slides.length - 1) {
        this.$emit('next')
      } else {
        this.$refs.slider.$refs.swiper.swiper.slideNext()
      }
    },
    skip() {
      const swiper = this.$refs.slider.$refs.swiper.swiper
      if (swiper.activeIndex === this.slides.length - 1) {
        this.$emit('skip')
      } else {
        this.$refs.slider.$refs.swiper.swiper.slideTo(this.slides.length - 1)
      }
    },
    onChangeSlide(index) {
      this.isLastSlide = index === this.slides.length - 1
    }
  },
}
</script>

<style scoped>

</style>
