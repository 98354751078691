<template>
  <div class="badge flex items-center justify-center"
       :class="classes">
    <span v-if="highlight" class="animate-ping absolute inline-flex h-full w-full rounded-md bg-report-error opacity-75"/>

    <Icon v-if="icon"
           :icon="icon"
           class="h-full p-[1px]"
           :class="{
             [iconSize]: iconSize,
             'mr-3': label && label !== '&nbsp;',
             [`${iconColor}`]: iconColor
           }"/>

    <Picture v-if="image"
             :sizes="['96:96']"
             class="h-full w-6 h-6 overflow-hidden rounded-full"
             resizing="cover"
             :class="{'mr-2': label}"
             :src="image"/>

    <span :class="{'truncate': truncate}" v-html="label"/>
  </div>
</template>

<script>
import {STYLE_TYPES} from "assets/js/constants/constant";
import Icon from "@/components/global/Icon.vue"
import Picture from "@/components/Picture.vue"

export default {
  name: "Badge",
  components: {
    Icon,
    Picture,
  },
  props: {
    label: String,
    type: {
      type: String,
      default: STYLE_TYPES.default
    },
    icon: String,
    image: String,
    iconColor: String,
    large: Boolean,
    border: Boolean,
    py: {
      type: String,
      default: 'py-[0.3rem]'
    },
    px: {
      type: String,
      default: 'px-5'
    },
    iconSize: {
      type: String,
      default: 'w-6 h-6'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    small: Boolean,
    truncate: Boolean,
    highlight: Boolean,
  },
  computed: {
    classes() {
      const bg = `${this.type}`
      return {
        [bg]: true,
        'rounded-full': this.rounded,
        'rounded-sm': !this.rounded,
        'border border-gray-400 border-solid': this.border,
        'text-10 px-2': this.small,
        'text-11': !this.large,
        'text-13': this.large,
        [this.py]: this.py,
        [this.px]: this.px,
        'w-10': !this.label,
        'relative': this.highlight
      }
    }
  }
}
</script>

<style scoped>

</style>
