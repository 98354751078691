<template>
  <div :class="{ 'flex items-center justify-center flex-col mr-4': !noStyle }">
    <component :class="(noStyle) ? 'w-full h-full' : 'w-10 scale-[1]'" :is="picto"></component>
  </div>

</template>

<script>
export default {
  props: {
    name: {type: String, default: "catalog/shoes"},
    noStyle: Boolean
  },
  computed: {
    picto() {
      this.name;
      return () => import(
          /* webpackChunkName: "icons" */
          /* webpackMode: "lazy-once" */
          `../assets/svg/${this.name}.svg?inline`
          )
    }
  }
};
</script>
