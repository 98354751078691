<template>
  <div class="w-full bg-secondary-500 h-14 flex items-center justify-center lg:justify-start">
    <div class="lg:content flex justify-between lg:w-full">
      <span v-for="(item,index) in values" :class="{'xs:pl-2 ' : index > 0}" class="flex items-center justify-center lg:justify-start  lg:mx-4 text-white lg:w-full overflow-hidden">
        <client-only><Icon :icon="item" class="lg:mr-6 stroke-current hidden lg:block w-10 max-h-[3rem]"/></client-only> <span class="text-10 lg:text-14 truncate leading-sm tracking-wide"><span class="lg:hidden" :class="{'hidden' : index === 0}">|</span> {{ $t(`header.top.${item}`) }}</span>
      </span>
      <client-only><SelectLang v-if="!navigatorLanguage?.includes('fr') && !isApp" class="ml-4" /></client-only>
    </div>
  </div>
</template>

<script>
import SelectLang from "@/components/buttons/SelectLang"
import Icon from "@/components/global/Icon"

export default {
  components: {
    SelectLang,
    Icon,
  },
  data() {
    return {
      navigatorLanguage: null,
    }
  },
  created() {
    if (process.client) {
      this.navigatorLanguage = window.navigator.language
    }
  },
  computed : {
    values() {
      const v = ['payment','delivery','protection'];
      if(this.$screen.desktop) {
        v.push('refund');
      }
      return v;
    },
    isApp() {
      return process.env.mobileApp
    }
  }
}
</script>
