<template>
  <div class="col-span-9 grid grid-cols-12">
    <div class="col-span-8 flex flex-col flex-wrap items-start min-h-[290px] max-h-[290px] gap-8">
      <nuxt-link
        :to="localePath({path : `/${subUniverseLink}`})"
        class="group w-1/2 text-14 font-bold text-left text-surface-medium hover:text-primary">
        <span class="inline-block leading-none border-b border-transparent group-hover:border-primary">{{ $t('navigation.all') }}</span>
      </nuxt-link>
      <nuxt-link v-for="child in subUniverseChildren"
                 @click.native="$emit('navigatedTo', child)"
                 :key="child.id"
                 :to="localePath({path : `/${child.url}`})"
                 class="group w-1/2 text-14 font-medium text-left text-surface-medium hover:text-primary ">
        <span class="inline-block leading-none border-b border-transparent group-hover:border-primary">{{ child.name }}</span>
      </nuxt-link>
    </div>
    <div class="col-span-4 py-sm-y px-10">
      <Picture class="w-full"
               :key="subUniverseImage"
               v-if="subUniverseImage"
               :src="subUniverseImage"
               :sizes="['408:544']"/>
    </div>
  </div>
</template>

<script>
import Picture from "@/components/Picture.vue"

export default {
  name: "SubUniverseNavigation",
  components: {Picture},
  props: {
    subUniverse: Object
  },
  computed: {
    subUniverseLink() {
      return this.subUniverse.url
    },
    subUniverseChildren() {
      return this.subUniverse.children
    },
    subUniverseImage() {
      return this.subUniverse.image;
    },
  }
}
</script>

<style scoped>

</style>
