var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',{attrs:{"customMessages":_vm.customMessages,"name":_vm.name,"rules":{'required' : _vm.required === true ? { allowFalse: false } : false}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{staticClass:"w-full flex cursor-pointer",class:{'items-center': !_vm.alignTop, 'justify-between flex-row-reverse': _vm.checkboxRight, [_vm.textSize]: true}},[_c('span',{staticClass:"border-2 border-solid rounded-md flex justify-center items-center p-2",class:{'bg-primary border-primary': _vm.value,
            'w-12 h-12': !_vm.small,
            'w-10 h-10': _vm.small,
            'mr-4': !_vm.checkboxRight,
            'ml-4': _vm.checkboxRight,
            'border-report-error': errors.length,
            'border-gray-medium': _vm.outlineDark && !errors.length && !_vm.value,
            'border-gray-400': !_vm.outlineDark && !errors.length}},[_c('BigCheck',{staticClass:"text-white",class:{'opacity-0': !_vm.value}})],1),_vm._v(" "),_c('input',{staticClass:"text-brand outline-none focus:ring-0 focus:ring-offset-0 flex-grow",attrs:{"hidden":"","type":"checkbox","name":_vm.name},domProps:{"value":_vm.value,"checked":_vm.value === true},on:{"change":function($event){return _vm.$emit('change', $event.target.checked)}}}),_vm._v(" "),_c('ClickableText',{class:{'text-surface-medium': !_vm.dark},attrs:{"text":_vm.label}})],1),_vm._v(" "),(errors.length)?_c('Errors',{attrs:{"errors":errors}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }