<template>
  <button type="button"
          :class="{'text-white bg-primary border-primary': active,
                   'rounded-full': rounded,
                   'border border-solid border-gray-400 hover:bg-primary hover:text-white hover:border-primary': border,
                   'bg-white border border-solid border-white': plain,
                   'p-3': xs && !noPadding,
                   'p-4': !small && !xs && !noPadding,
                   'p-5': small && !noPadding,
                   'pointer-events-none': loading,
                   'shadow-blur': shadow}">
    <Icon v-if="!loading" :class="{
                        'w-5 h-5': xs,
                        'w-6 h-6': !xs && small,
                        'w-8 h-8': !xs && !small}"
               :icon="icon"/>
    <Loading v-else
             :class="{'w-6 h-6': small, 'w-8 h-8': !small}"/>
  </button>
</template>

<script>
import Loading from "@/components/Loading.vue"
import Icon from "@/components/global/Icon.vue"

export default {
  name: 'IconButton',
  components: {
    Icon,
    Loading,
  },
  props: {
    icon: String,
    active: false,
    border: Boolean,
    rounded: {
      type: Boolean,
      default: true
    },
    plain: Boolean,
    shadow: Boolean,
    small: Boolean,
    noPadding: Boolean,
    loading: Boolean,
    xs: Boolean
  }
}
</script>

<style scoped lang="scss">
  .shadow-blur {
    box-shadow: 0 0 10px 0 #00000010 ;
  }
</style>
