<template>
  <div class="w-full bg-white">
    <div
      class="container mx-auto grid grid-cols-12 py-14 gap-20 px-sm-x">
      <div
        class="col-span-3 flex flex-col gap-10 items-start border-r border-surface-light">
        <nuxt-link class="text-14 font-medium flex items-center hover:text-primary uppercase"
                   :to="localePath({path : `/${universeLink}`})" >
          <SvgIcon class="w-8 h-10 mr-6"
                   :name="`catalog/all`"/>
          {{ $t('navigation.all') }}
        </nuxt-link>
        <nuxt-link v-for="(subUniverse, i) in universeChildren"
                   :to="localePath({path : `/${subUniverse?.url}`})"
                   event=""
                   :key="subUniverse.id"
                   @click.native.prevent="$emit('selectSubUniverse', subUniverse)"
                   class="relative text-14 font-medium flex items-center w-full hover:text-primary"
                   :class="{'text-primary before:content-[``] before:rounded-l-sm before:z-0 before:absolute before:-left-4 before:-top-4 before:right-0 before:-bottom-4 before:bg-form-bg' : (subUniverseSelected?.id === subUniverse?.id) || (!subUniverseSelected && i === 0)}">
          <SvgIcon class="w-8 h-10 mr-6" :name="`catalog/${subUniverse?.id}`"/>
          <span class="relative z-[1]">{{ subUniverse?.name }}</span>
        </nuxt-link>
      </div>
      <SubUniverseNavigation v-for="(subUniverse, i) in universeChildren"
                             v-show="subUniverseSelected?.id === subUniverse?.id"
                             :key="subUniverse?.id"
                             @navigatedTo="$emit('navigatedTo', $event)"
                             :sub-universe="subUniverse"/>

    </div>
  </div>
</template>

<script>
import SubUniverseNavigation from "@/components/header/SubUniverseNavigation.vue"
import SvgIcon from "@/components/SvgIcon.vue"

export default {
  name: "UniverseNavigation",
  components: {SvgIcon, SubUniverseNavigation},
  props: {
    universe: Object,
    subUniverseSelected: Object
  },
  computed: {
    universeLink() {
      return this.universe?.url
    },
    universeChildren() {
      return this.universe?.children
    },
  }
}
</script>

<style scoped>

</style>
