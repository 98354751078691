<template>
  <nuxt-link
             class="flex self-start items-center bg-surface-light hover:bg-primary-50 rounded-full px-2 pr-4 py-2 text-14"
             :to="localePath({name : 'brand-slug', params : {slug : brandLink(brand.slug, $i18n)}})">

            <span class="flex items-center justify-center overflow-hidden w-10 h-10 bg-white rounded-full mr-4">

              <Picture class="w-full bg-white" :noBg="true" :resizing="'fit'"
                       :src="brand.image"
                       :sizes="['50:50']"/>
            </span>
    <span class="text-primary-900 capitalize-first inline-block">{{ brand.name }}</span>
  </nuxt-link>
</template>

<script>
import {brandLink} from "assets/js/utils/string";
import Picture from "@/components/Picture.vue";
export default {
  components: {Picture},
  props : {
    brand : Object
  },
  methods: {
    brandLink
  },
}
</script>
