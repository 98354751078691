<template>
  <validation-provider ref="provider" tag="div" :name="name" v-slot="{ errors }"
                       :rules="{'required' : required === true,'uploaderror' : true, 'array_between': {min,max}}"
                       :class="{'sm:bg-primary-50 ' : dragHover, '' : !dragHover && multiple}"
                       class="relative w-full h-auto py-8">
    <div class="w-full h-full flex flex-col items-center justify-center gap-10">
      <div class="absolute top-5 left-0 grid grid-cols-3 w-full"
           :class="{hidden : max === 1 || files.length === 0, 'grid gap-6' :  max > 1 && files.length > 0}">
        <span
          class="h-[16rem] sm:h-[20rem] aspect-w-1 text-center text-surface-medium capitalize text-14 flex items-end justify-center"
          :class="{'opacity-0' : index >= files.length}"
          v-for="(side, index) in sides">
          {{ $t(`sides.${side}`) }}
        </span>
      </div>

      <div ref="list" class="w-full"
           :class="{hidden : files.length === 0, 'grid grid-cols-3 gap-6' :  max > 1 && files.length > 0, 'flex items-center justify-center' : max === 1}">

        <FilePreview v-for="(file, index) in files" :key="file.key"
                     ref="filePreview"
                     @preview="$emit('preview', $event)"
                     :highlight="isHighlighted(file, index) ? $t('principal') : null"
                     :class="{'w-80' : max === 1}" class="File"
                     :loader="loader"
                     :file="file" @error="onError"
                     :processFunc="filePondProcessFile"
                     @delete="onRemoveFile"
                     @rotate="onRotateFile"
                     @uploaded="onFileUploaded(file, $event)"/>

        <div v-if="files.length < max" class="overflow-hidden">
          <div class="w-full aspect-w-1 h-[13rem] sm:h-[22rem]">
            <div class="h-full">
              <InputFiles class="h-full"
                          :force-icon="true"
                          :multiple="multiple"
                          @files="onInput"/>
            </div>
          </div>
        </div>

        <div class="overflow-hidden" v-if="isApp && isAndroid">
          <div class="w-full aspect-w-1 h-[13rem] sm:h-[22rem]">
            <div class="h-full">
              <InputFiles class="h-full"
                          :capture="true"
                          :multiple="multiple"
                          @files="onInput"/>
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-4 w-full" v-if="!files.length">
        <InputFiles v-if="!files.length"
                    ref="inputFiles"
                    @files="onInput"
                    :multiple="multiple"
                    :label="galleryLabel"/>

        <InputFiles v-if="!files.length && isApp && isAndroid"
                    @files="onInput"
                    :capture="true"
                    :multiple="multiple"
                    :label="cameraLabel"/>
      </div>

      <Errors ref="errors" v-if="errors.length" :errors="errors"/>

      <div v-if="files.length && multiple" class="text-13 m-auto justify-center flex items-center">
        <Move class="mr-4" />{{ $t('fileExplorer.multiple.tips') }}
      </div>

    </div>

  </validation-provider>
</template>

<script>

import Sortable, {Swap} from 'sortablejs';
 import {ValidationProvider} from "vee-validate";

import AddPicture from "~/assets/svg/add_picture.svg?inline";
import Upload from "~/assets/svg/cloud_upload.svg?inline";
import Camera from "~/assets/svg/camera.svg?inline";
import ProfilPicture from "~/assets/svg/picture.svg?inline";
import Move from "~/assets/svg/move.svg?inline"
import InputFiles from "@/components/form/InputFiles"
import FilePreview from "@/components/form/FilePreview"
import Errors from "@/components/form/Errors"
import {FileUpload} from "@/mixins/filepond";
import Help from "~/assets/svg/infos.svg?inline";

const MAX_FILES = 12;

export default {
  components: {
    Help,
    FilePreview,
    InputFiles,
    ValidationProvider,
    Errors,
    Camera,
    ProfilPicture,
    Upload,
    AddPicture,
    Move
  },
  mixins: [FileUpload],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    loader : Boolean,
    required: {
      type: Boolean,
      default: true
    },
    min: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: 'fileExplorer'
    },
    images: {
      type: Array,
      default: () => {
        return []
      }
    },
    imagesRotations: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: true
    },
    max: {
      type: Number,
      default: MAX_FILES
    },
    confirmDeletion: Boolean,
  },
  data: () => ({
    dragHover: false,
    sortable: null,
    highlightedFileKey: null,
    files: [],
    filesIds: [],
    sides: ['front', 'side-left', 'side-right', 'under']
  }),
  computed: {
    cameraLabel() {
      if (this.isApp) {
        return this.$t('fileExplorer.app.camera');
      } else {
        return this.$t('fileExplorer.' + (this.multiple ? 'multiple' : 'single') + '.add');
      }
    },
    galleryLabel() {
      return this.$t('fileExplorer.' + (this.multiple ? 'multiple' : 'single') + '.add');
    },
    isAndroid() {
      return process.env.mobileApp && this.$capacitor.platform === 'android';
    },
    isApp() {
      return process.env.mobileApp;
    },
    uploading() {
      return this.filesIds.some(item => (item === 'loading'));
    },
    error() {
      return this.filesIds.some(item => (item === 'error'));
    },
  },
  watch: {
    uploading(value) {
      this.$emit('uploading', value);
    },
    error(value) {
      this.$emit('error', value);
    },
    value(value) {
      this.$refs.provider.validate(value);
      //this.filesIds = value;
    },
    images(value) {
      this.addImages(value);
    },
    files(value) {

    }
  },
  mounted() {
    // can't find a solution to mount once
    try {
      Sortable.mount(new Swap());
    } catch (e) {

    }

    this.sortable = Sortable.create(this.$refs.list, {
      swap: true,
      swapThreshold: 2,
      swapClass: 'highlighted',
      animation: 0,
      delayOnTouchOnly: true,//!this.isApp,
      delay: 0,
      ghostClass: "sortable-ghost",  // Class name for the drop placeholder
      chosenClass: "sortable-chosen",  // Class name for the chosen item
      dragClass: "sortable-drag",
      draggable: '.File',
      handle: '.preview',
      onSort: () => {
        this.update();
      }
    });

    if (this.images && this.images.length) {
      this.addImages(this.images);
    }

    this.$el.addEventListener('drop', (e) => {
      if (e.dataTransfer.files.length) {
        this.filterFiles(e.dataTransfer.files);
      }
      this.dragHover = false;
      e.preventDefault();
    })

    this.$el.addEventListener('dragover', (e) => {
      this.dragHover = true;
      e.preventDefault();
    });

    this.$el.addEventListener('dragexit', (e) => {
      this.dragHover = false;
      e.preventDefault();
    });
  },
  beforeDestroy() {
    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }
  },
  methods: {
    addImages(value) {
      let f = value;
      f = f.map((file, index) => ({
        src: file,
        id: file,
        type: 'url',
        key: Date.now() + '-' + index,
        rotation: this.imagesRotations[index] ?? 0
      }));
      this.files = f;
    },
    update() {
      if (this.sortable) {
        const keys = this.sortable.toArray()
        this.highlightedFileKey = keys[0]

        const value = keys.map(key => {
          const file = this.files.find(file => file.key === key)
          return file.serverID ?? file.id
        });

        this.$emit('rotations', this.files.map(file => {
          return {
            id: file.id,
            serverID: file.serverID,
            rotation: file.rotation,
          }
        }))
        this.$emit('input', value);
        this.$emit('change', value);
        this.filesIds = value;
      }
    },
    filterFiles(files) {
      const f = [...this.files];
      f.length = Math.min(this.max, f.length);

      Array.from(files).forEach((file, index) => {
        const acceptedType = ['image/png', 'image/jpeg', 'image/jpg', 'image/heic'];
        if (acceptedType.includes(file.type)) {
          let found = false;
          if (!found && f.length < this.max) {
            f.push({
              src: file,
              type: 'file',
              id: file.name,
              serverID: 'loading',
              key: Date.now() + '-' + index,
              rotation: 0,
            });
          }
        }
      });
      this.files = f;
      this.$nextTick(() => {
        this.update();
      });
    },
    onFileUploaded(updatedFile, {serverID, rotation}) {
      const [file] = this.files.filter(function (file) {
        return file.key === updatedFile.key
      })
      file.serverID = serverID
      file.rotation = rotation

      this.$nextTick(() => {
        this.update();
      });
    },
    isHighlighted(file, index) {
      if (this.highlightedFileKey) {
        return file.key === this.highlightedFileKey
      } else {
        return index === 0
      }
    },
    onError() {
      this.$nextTick(() => {
        this.update();
      });
    },
    async onRemoveFile(file, filepondID) {
      const deleteFile = function(remove) {
        if (remove) {
          const filesCopy = [...this.files];
          for (let i = 0; i < filesCopy.length; i++) {
            if (filesCopy[i].id === file.id) {
              filesCopy.splice(i, 1);
              this.files = filesCopy;
              break;
            }
          }

          if (filepondID) {
            //this.$store.dispatch('upload/removeFile', filepondID);
            //this.$filepond.removeFile(filepondID);
            this.filePondRemoveFile(filepondID);
          }

          this.$nextTick(() => {
            this.update();
            this.$emit('delete')
          });
        }
      }.bind(this)

      if (this.confirmDeletion) {
        await this.$store.dispatch('openForm', {
          width: 'big',
          component: 'action',
          type: 'popinV2',
          callback: deleteFile,
          data: {
            title: this.$t('photo.delete.title'),
            description: this.$t('photo.delete.description'),
            ctaLabel: this.$t('dialog.delete')
          }
        })
      } else {
        deleteFile(true)
      }

    },
    async onRotateFile(file, filepondID, rotation) {
      const [fileToRotate] = this.files.filter((f) => f.id === file.id)

      fileToRotate.rotation = rotation

      this.update();
    },
    onInput(files) {
      this.filterFiles(files);
      this.$emit('on-file-input');
    }
  }
}
</script>

<style lang="scss">
.highlighted {
  transition: box-shadow 150ms;
  @apply opacity-50;
  box-shadow: inset 0 0 0 4px theme('colors.primary.500');
}
</style>
