<template>
  <div class="h-full flex flex-col justify-center px-6 pt-nav-safe">
    <TextRow text-center
             title-size="text-18"
             description-size="text-14 font-medium"
             :label="title"
             :description="description" />

    <Picture class="h-[16rem] my-10"
             :resizing="'fit-contain'"
             noBg
             :sizes="['900:900']" :src="image"/>

    <div class="flex flex-col gap-12 items-center">
      <div v-for="(content, index) in contents"
           class="flex flex-col items-center"
           :key="index">
       <TextRow description-size="text-14 font-medium"  text-center :label="content.title" :description="content.description" />
      </div>
    </div>
  </div>
</template>

<script>
import TextRow from "@/components/global/TextRow.vue"
import Picture from "~/components/Picture.vue"
export default {
  name: "ImageSlide",
  components: {Picture, TextRow},
  props: {
    title: String,
    description: String,
    image: String,
    contents: Array
  }
}
</script>

<style scoped>

</style>
