<template>
  <button
    class="text-14 text-primary ml-20">
    <slot />
  </button>
</template>

<script>
export default {
  components : {},
}
</script>
