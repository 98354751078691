<template>
  <Swiper class="w-full"
          :slides-per-view="1"
          height-full
          @change="$emit('change', $event)"
          ref="swiper"
          bullets
          pagination>
    <template v-slot="{readyHandler, initialized}">
      <SwiperSlide class="w-full h-full pb-20"
                   v-for="(slide, index) in slides"
                   @ready="readyHandler"
                   :key="index">
        <component :is="slide.type"
                   v-bind="slide"/>
      </SwiperSlide>
    </template>
  </Swiper>
</template>

<script>
import TitleSlide from "@/components/slider/TitleSlide.vue"
import ImageSlide from "@/components/slider/ImageSlide.vue"
import TextSlide from "@/components/slider/TextSlide.vue"
import BigImageSlide from "@/components/slider/BigImageSlide.vue"
import Swiper from "~/components/Swiper.vue"
import SwiperSlide from "~/components/SwiperSlide.vue"

export default {
  name: "FullHeightSlider",
  components: {SwiperSlide, Swiper, TitleSlide, ImageSlide, TextSlide, BigImageSlide },
  props: {
    slides: Array,
  },
}
</script>
