<template>
  <div class="flex w-full justify-between px-8 py-2 left-0 z-50 md:z-10 transition-all"
       :class="classes">

    <div class="min-w-[4.5rem] min-h-[4.5rem] flex justify-center items-center">
      <slot name="leftButtons" />
    </div>

    <component :is="to ? 'nuxt-link': 'div'" :to="to" v-if="$slots.default"
               :class="{[titleSize]: titleSize}"
               class="font-bold font-sans flex items-center mx-auto overflow-hidden justify-center flex-grow text-center w-[70%]">
      <slot/>
    </component>

    <div class="min-w-[4.5rem] min-h-[4.5rem]">
      <slot name="rightButtons" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopNav',
  props: {
    title: {
      type: String,
      default: null
    },
    leftButtons: {
      type: Array,
      default: null
    },
    rightButtons: {
      type: Array,
      default: null
    },
    transparent: Boolean,
    to: String,
    sticky: {
      type: Boolean,
      default: true
    },
    noNotch: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true
    },
    titleSize: {
      type: String,
      default: 'text-14'
    }
  },
  computed: {
    classes() {
      return {
        'bg-white': !this.transparent,
        'border-b border-solid border-gray-400': !this.transparent && this.border,
        'border-b border-transparent bg-transparent': this.transparent,
        'top-0 fixed md:absolute notch-pt-nav': this.sticky,
        'relative': !this.sticky
      }
    }
  },
}
</script>

