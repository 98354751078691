<template>
  <div class="h-full flex flex-col justify-center px-10">
    <Picture class="h-24 my-6"
             :resizing="'fit-contain'"
             noBg
             :sizes="['700:700']" :src="image"/>

    <TextRow text-center
             :label="title"
             :description="description" />
    <nuxt-link :to="link" v-if="cta" class="text-16 underline font-medium block text-center">{{ cta }}</nuxt-link>
  </div>
</template>

<script>
import TextRow from "@/components/global/TextRow.vue"
import Picture from "~/components/Picture.vue"
export default {
  name: "TextSlide",
  components: {Picture, TextRow},
  props: {
    title: String,
    description: String,
    image: String,
    cta: String,
    link: String,
  }
}
</script>

<style scoped>

</style>
