<template>
  <div class="w-full py-6">
    <div
      class="container mx-auto grid grid-cols-12 gap-16">
      <div v-for="universe in universes.slice(0, universes.length - 1)"
           class="col-span-2 flex flex-col items-start justify-start">
        <nuxt-link @click.native="$emit('navigatedTo', universe)"
                   class="w-full mb-6"
                   :to="localePath({path : `/${universe.url}`})">
          <Picture :src="universe.image"
                   :sizes="['288:432']"></Picture>
        </nuxt-link>

        <nuxt-link class="group font-medium text-14 mb-6 text-left hover:text-primary"
                   @click.native="$emit('navigatedTo', universe)"
                   :to="localePath({path : `/${universe.url}`})">
          <span class="inline-block leading-none border-b border-transparent group-hover:border-primary">{{ universe.name }}</span>
        </nuxt-link>
        <nuxt-link v-for="child in universe.children"
                   :key="child.id"
                   :to="localePath({path : `/${child.url}`})"
                   @click.native="$emit('navigatedTo', child)"
                   class="group text-surface-medium text-14 text-left mb-2 hover:text-primary">

          <span class="inline-block leading-none border-b border-transparent group-hover:border-primary capitalize-first">{{ child.name.toLowerCase() }}</span>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Picture from "@/components/Picture.vue"

export default {
  name: "SportsNavigation",
  components: {Picture},
  computed: {
    ...mapGetters({
      nav: 'nav/getNav',
    }),

    universes() {
      return this.nav.filter(item => item.isUniverse === 1 && item.id !== 220)
    },
  }
}
</script>

<style scoped>

</style>
