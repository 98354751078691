<template>
  <div class="h-full flex flex-col items-center justify-around px-6">
    <Picture class="w-1/2 rounded-lg shadow-product overflow-hidden my-4"
             :resizing="'fit-contain'"
             noBg
             :sizes="['900:1000']" :src="image"/>

    <div class="flex flex-col items-center">
      <TextRow text-center
               title-size="text-18"
               description-size="text-14 font-medium"
               :label="title"
               :description="description" />
    </div>
  </div>
</template>

<script>
import TextRow from "@/components/global/TextRow.vue"
import Picture from "~/components/Picture.vue"
export default {
  name: "BigImageSlide",
  components: {Picture, TextRow},
  props: {
    title: String,
    description: String,
    image: String,
    contents: Array
  }
}
</script>

<style scoped>

</style>
