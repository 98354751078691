<template>
  <div ref="search" :class="{'h-22' : small, 'flex-grow h-22 lg:max-w-5xl' : !small}">
    <div class="relative w-full h-full border border-gray-400 rounded-full overflow-hidden" :class="(type === 1) ? 'bg-white shadow-btn' : 'bg-gray-200'">
      <div class="flex items-center absolute left-10 top-0 h-full pointer-events-none">
        <SearchIcon class="w-7 text-black"/>
      </div>
      <form class="relative w-full h-full pl-24" action="." @submit.prevent>
        <div v-show="showPlaceholder && !q " class="absolute top-0 left-0 pl-24 flex h-full flex-col justify-center pointer-events-none">
          <span class="text-14 leading-6 font-bold text-black">{{ titlePlaceholder }}</span>
          <span v-if="type === 1 && textPlaceHolder !== ''" class="text-14 leading-6 text-gray-medium">{{ textPlaceHolder }}</span>
        </div>
        <input ref="input" type="search" @focusin="onFocus" @blur="onBlur" @input="onSearch" class="w-full h-full text-14 text-black bg-transparent appearance-none outline-none"/>
      </form>
      <div v-if="searchStatus" class="absolute right-0 top-0 flex items-center h-full pr-8" @click="clearSearch()">
        <DeleteIcon class="w-9 text-black bg-transparent"/>
      </div>
    </div>
    <div v-if="autoComplete && (formatedQ.length > 0 || $screen.mobile)" class="absolute z-50 left-0 w-full px-sm-x mt-2 flex flex-col overflow-y-auto bg-white sm:h-auto sm:relative sm:left-auto sm:max-h-[400px]" :class="{ 'height-safe': searchStatus }">
      <div v-if="results.length > 0 && searchStatus" class="w-full py-14 flex text-16 font-medium border-b border-gray-400 lg:hover:bg-surface-light lg:py-8" v-for="result in results" :key="result.id">
        <a @click="clearSearch(result.name); $router.push(localePath({path : '/' + result.url}))" class="flex items-center w-full px-sm-x" v-once >
          <span class="pr-4" v-html="result.name"></span>
          <div class="h-8 flex justify-center items-center bg-primary-50 text-10 text-center whitespace-nowrap rounded-full ml-auto px-4" v-if="result.parent">
            <span>{{ result.parent }}</span>
          </div>
        </a>
      </div>
      <div v-if="searchStatus && formatedQ.length === 0 && $screen.mobile" class="w-full py-14 flex text-16 font-medium lg:hover:bg-surface-light lg:py-8">
        <button class="flex w-full px-sm-x">
          <span class="font-bold">{{ titlePlaceholder }}</span> <span class="ml-2">{{ textPlaceHolder }}</span>
        </button>
      </div>
      <div v-if="searchStatus && formatedQ.length > 0" class="w-full py-14 flex text-16 font-medium lg:hover:bg-surface-light lg:py-8">
        <button class="flex w-full px-sm-x" @click="onSearchString">
          <span class="font-bold">{{ $t('search.label') }}</span><span class="ml-2">{{ formatedQ }}</span>
        </button>
      </div>
      <div v-if="results.length > 0" class="w-full pb-footer-safe"></div>
    </div>
  </div>
</template>

<script>
import {debounce} from "underscore";
import { GTM_EVENTS } from "~/assets/js/gtm-events";
// ICON
import SearchIcon from "~/assets/svg/search.svg?inline";
import DeleteIcon from "~/assets/svg/delete.svg?inline";
import {mapGetters, mapMutations} from "vuex";

export default {
  props: {
    small: Boolean,
    autoComplete: Boolean,
    placeholder: String,
    descPlaceholder: String,
    watchQuery: {
      type: Boolean,
      default: true
    },
    // TYPE : 0 = Bg grey + 1 text / 1: Bg white + shadow + 2 text
    type: {
      type: Number,
      default: 0
    }
  },
  components: {
    SearchIcon,
    DeleteIcon
  },
  data: () => ({
    results: [],
    q: null,
    showPlaceholder: true,
    customPlaceholder: null,
    customDescPlaceholder: null,
  }),
  watch: {
    '$route'() {
      this.results = [];
      if (this.watchQuery) {
        this.$refs.input.value = this.q = this.$route.query.q ? this.$route.query.q : '';
        if (!this.q) this.showPlaceholder = true
      }
    }
  },
  computed: {
    ...mapGetters({
      searchStatus: 'search/getSearchStatus'
    }),
    formatedQ() {
      return this.q ? this.q.trim() : '';
    },
    titlePlaceholder() {
      // Props
      if (this.placeholder) {
        return this.placeholder
      }
      // Custom event
      if (this.customPlaceholder !== null) {
        return this.customPlaceholder
      }
      // Default
      return (this.type === 1) ? this.$t('search.placeholder') : this.$t('search.placeholder.long')
    },
    textPlaceHolder() {
      // Props
      if (this.descPlaceholder) {
        return this.descPlaceholder
      }
      // Custom
      if (this.customDescPlaceholder !== null) {
        return this.customDescPlaceholder
      }
      // Default
      return this.$t('search.placeholder.desc')
    }
  },
  activated() {
    this.showPlaceholder = true
    this.setSearchStatus(false);
  },
  mounted() {
    if (this.autoComplete) {
      this.$el.addEventListener('keyup', this.onKey);
    }
    if (this.watchQuery) {
      this.$refs.input.value = this.$route.query.q && this.$route.query.q.length ? this.$route.query.q : '';
      this.q = this.$refs.input.value;
    } else {
      this.$refs.input.value = this.q = ''
    }

    // Focus input search
    this.$root.$on('search:focus', () => {
      if (this.$refs?.input) {
        this.$nextTick(() => { this.$refs.input.focus() })
      }
    })

    // Update custom placeholder
    this.$root.$on('search:update-placeholder', (data) => {
      this.customPlaceholder = data?.placeholder
      this.customDescPlaceholder = data?.descPlaceholder
      this.$forceUpdate()
    })

    // Reset custom placeholder
    this.$root.$on('search:reset-placeholder', () => {
      this.customPlaceholder = null
      this.customDescPlaceholder = null
      this.$forceUpdate()
    })

    // Close search
    this.$root.$on('search:close', () => {
      this.clearSearch()
    })
  },
  deactivated() {
    this.$root.$off('search:close')
  },
  beforeDestroy() {
    this.setSearchStatus(false)
    // DESTROY EVENTS
    this.$root.$off('search:focus')
    this.$root.$off('search:update-placeholder')
    this.$root.$off('search:reset-placeholder')
    // DESTROY WINDOW
    if (this.autoComplete) {
      this.$el.removeEventListener('keyup', this.onKey);
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  methods: {
    ...mapMutations({
      setSearchStatus: 'search/setSearchStatus'
    }),
    clearSearch(name) {
      this.$emit('input', null);
      this.setSearchStatus(false);
      this.$root.$emit('search:value', '')
      if (name) {
        this.pushSearchEvent(name);
      }
      this.results = [];
      this.setSearchStatus(false);
      this.q = null;
      this.showPlaceholder = true
      if (this.$refs?.input) {
        this.$refs.input.value = null
      }
    },

    pushSearchEvent(term) {
      this.$gtm.push({
        event: GTM_EVENTS.search.validated,
        search: { term },
      });
    },
    onFocus(e) {
      this.setSearchStatus(true);
      this.showPlaceholder = false;
      this.q = e.target.value;
      this.$gtm.push({ event: GTM_EVENTS.search.begin });
    },
    onBlur(e) {
      this.showPlaceholder = this.q.length === 0
    },
    onKey(e) {
      if (this.q !== this.$route.query.q && this.searchStatus && e.keyCode === 13) {
        e.stopPropagation();
        e.preventDefault();
        this.onSearchString();
      }
    },
    onSearchString() {
      this.setSearchStatus(false);
      this.results = [];

      const path = '/' + this.$i18n.locale + '/' + this.$t('search.equipment');

      this.$router.push({
        path: path,
        query: {...this.$route.query, q: this.formatedQ.length ? this.formatedQ : null}
      });
      this.pushSearchEvent(this.formatedQ);
    },
    onSearch: debounce(function (e) {
      this.$root.$emit('search:value', e.target.value)
      this.$emit('input', e.target.value);
      this.q = e.target.value;
      this.setSearchStatus(true);
      this.results = [];

      if (this.autoComplete && this.q.length >= 3 ) {
        this.search(e.target.value);
      }
    }, 100),
    async search(query) {
      const resp = await this.$store.dispatch('nav/searchByString', query)
      const result = JSON.parse(JSON.stringify(resp))
      //result.length = Math.min(result.length, 5);

      // HIGHLIGHT SEARCH TERM
      if (Object.keys(result).length > 0) {
        Object.keys(result).map(key => {
          const obj = result[key]
          const index = obj.name.toLowerCase().indexOf(query.toLowerCase())
          if (index >= 0) {
            const text = obj.name
            obj.name = text.substring(0, index) + `<span class="text-primary font-bold">${ text.substring(index, index + query.length) }</span>` + text.substring(index + query.length)
          }
        })
      }

      this.results = result;
    }
  }
}
</script>
