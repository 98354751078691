<template>
  <div class="select-none w-full relative select-none" :class="{'h-full' : heightFull, 'flex' : navigation}">
    <div :class="{'hidden' : !(navigation && showPrev) }" @click="swiper.slidePrev()" ref="prev" class="absolute z-[3] left-0 h-full flex justify-center items-center">
      <div class="btn-circle ml-8">
        <ArrowLeftIcon class="w-6 h-6"/>
      </div>
    </div>
    <div ref="slider" class="swiper-container w-full relative z-[2]" :class="{'h-full' : heightFull }">
      <span v-if="startFade"
            class="absolute -left-10 sm:-left-20 top-0 h-full w-40 sm:w-80 bg-gradient-to-r z-10 bg-opacity-20 transition-opacity pointer-events-none"
            :class="{'opacity-0' : !gradientStartActive, 'from-white': !fadeColor, [fadeColor] : fadeColor}"/>
      <div class="swiper-wrapper items-stretch" :class="!initialized ? initClasses : ''" >
        <slot :readyHandler="readyHandler" :updateHandler="updateHandler" :initialized="initialized"></slot>
      </div>
      <span v-if="fade"
            class="absolute justify-center items-center flex -right-10 sm:-right-20 top-0 h-full w-40 sm:w-80 bg-gradient-to-l z-10 bg-opacity-20 transition-opacity pointer-events-none"
            :class="{'opacity-0' : !gradientActive, 'from-white': !fadeColor, [fadeColor] : fadeColor}">
              <div v-if="showIndicator && !touchStart" class="animate-translate-fade w-12 h-12 bg-gray-400 rounded-full p-[2px]">
                <div class="rounded-full bg-white w-full h-full"></div>
              </div>
      </span>
    </div>
    <div :class="{'hidden' : !(navigation && showNext) }"  @click="swiper.slideNext()" ref="next" class="absolute z-[3] right-0 h-full flex justify-center items-center">
      <div class="btn-circle mr-8">
        <ArrowIcon class="w-6 h-6"/>
      </div>
    </div>
    <!-- PAGINATION -->
    <div v-if="pagination || bottomBadges?.length" class="flex flex-col absolute bottom-0 w-full z-10 px-8 pb-4">
      <div v-if="pagination && !bullets" ref="pagination" class="w-auto relative items-center justify-center bg-black text-white text-12 rounded-full px-6 py-2 self-end"></div>
      <div v-if="pagination && bullets" ref="pagination" class="w-auto relative flex items-center m-auto justify-center self-end gap-4"></div>
      <div class="grid gap-2"
           :class="['grid-cols-' + bottomBadges?.length]">
        <div v-for="badge in bottomBadges"
             class="bg-white rounded-full px-2 py-1 text-11 text-center font-medium border border-solid border-surface-light"
             :key="badge">
          {{ badge }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Swiper, {Pagination, Navigation, Thumbs, Keyboard, Mousewheel, Zoom} from "swiper";
// ICON
import ArrowIcon from "~/assets/svg/arrow.svg?inline";
import ArrowLongIcon from "~/assets/svg/arrow-long.svg?inline";
import ArrowLeftIcon from "~/assets/svg/arrow-left.svg?inline";
import Badge from "@/components/global/Badge"

Swiper.use([Pagination, Navigation, Thumbs, Keyboard, Mousewheel, Zoom]);

export default {
  components: {
    Badge,
    ArrowIcon,
    ArrowLongIcon,
    ArrowLeftIcon
  },
  activated() {
    if (this.swiper) {
      this.swiper.update();
    }
  },
  props: {
    initClasses : {
      type : String,
      default : 'flex'
    },
    thumbs: Object,
    initialSlide: {
      type: Number,
      default: 0
    },
    showIndicator: Boolean,
    centeredSlides: Boolean,
    fadeColor: String,
    keyboard: Boolean,
    loop: Boolean,
    height: Number,
    pagination: Boolean,
    startFade: Boolean,
    fade: {
      type: Boolean,
      default: false
    },
    freeMode: Boolean,
    mousewheel: Boolean,
    slidesOffsetBefore: Number,
    slidesOffsetAfter: Number,
    heightFull: Boolean,
    navigation: Boolean,
    watchSlidesProgress: Boolean,
    spaceBetween: {
      type: Number,
      default: 0
    },
    slidesPerView: {
      type: [Number, String],
      default: 1
    },
    breakpoints: {
      type: Object
    },
    direction: {
      type: String,
      default: 'horizontal'
    },
    zoom: Boolean,
    bottomBadges: {
      type: Array,
      default: () => []
    },
    bullets: Boolean,
  },
  data: () => ({
    initialized: false,
    swiper: null,
    timeout: -1,
    gradientActive: true,
    gradientStartActive: false,
    needFade: false,
    thumbsTarget: null,
    showPrev: false,
    showNext: false,
    touchStart: false,
  }),
  watch: {
    navigation(value) {
      this.$nextTick(() => {
        this.updateHandler();
      })
    },
    thumbs(value) {
      this.thumbsTarget = value;
      this.initialize();
    }
  },
  /*beforeDestroy() {
    clearTimeout(this.timeout);
    this.timeout = null;
    if (this.swiper) {
      this.swiper.off('breakpoint', this.onBreakpoint);
      this.swiper.off('reachEnd', this.reachEnd);
      this.swiper.off('fromEdge', this.fromEdge);
      this.swiper.off('slideChange', this.onChange);
      this.swiper.off('touchMove', this.onMove);
      this.swiper.destroy();
      this.swiper = null;
      this.thumbsTarget = null;
    }
  },*/
  methods: {
    initialize() {
      this.initialized = true;
      this.swiper = new Swiper(this.$refs.slider, {
        threshold : 5,
        zoom: this.zoom,
        watchSlidesProgress: this.watchSlidesProgress,
        loop: this.loop,
        height: this.height,
        initialSlide: this.initialSlide,
        slidesPerView: this.slidesPerView,
        centeredSlides: this.centeredSlides,
        freeMode: this.freeMode,
        ...this.mousewheel && {
          mousewheel: {
            forceToAxis: true,
          }
        },
        direction: this.direction,
        spaceBetween: this.spaceBetween || 0,
        slidesOffsetBefore: this.slidesOffsetBefore || 0,
        slidesOffsetAfter: this.slidesOffsetAfter || 0,
        ...this.keyboard && {
          keyboard: {
            enabled: true,
            onlyInViewport: false,
          }
        },
        ...this.thumbs && {
          thumbs: {
            swiper: this.thumbsTarget,
            slideThumbActiveClass: 'thumbActive'
          }
        },
        ...this.navigation && {
          navigation: {
            disabledClass : "opacity-50 pointer-events-none",
            //nextEl: this.$refs.next,
            //prevEl: this.$refs.prev
          }
        },
        ...this.breakpoints && {breakpoints: this.breakpoints},
        ...this.pagination && {
          pagination: {
            el: this.$refs.pagination,
            type: this.bullets ? 'bullets' : 'fraction',
            clickable: this.bullets
          }
        },
      });

      // Active navigation btns
      this.showPrev = this.swiper.activeIndex > 0 || this.loop
      this.showNext = this.swiper.slides.length > 1 || this.loop

      this.swiper.on('breakpoint', this.onBreakpoint);
      this.swiper.on('reachEnd', this.reachEnd);
      this.swiper.on('reachBeginning', this.reachBeginning);
      this.swiper.on('fromEdge', this.fromEdge);
      this.swiper.on('slideChange', this.onChange);
      this.swiper.on('init', this.onInit);
      this.swiper.on('touchMove', this.onMove);
      this.swiper.on('touchStart', () => this.touchStart = true);

      this.$emit('ready', this.swiper);

      setTimeout(() => {
        this.$nextTick(() => {
          this.updateHandler();
        },2000)
      })

    },
    onMove(swiper, e) {
        e.stopPropagation();
    },
    onPrev() {
      //this.swiper.slidePrev();
      this.$emit('prev');
    },
    onNext() {
      //this.swiper.slideNext();
      this.$emit('next');
    },
    onInit() {},
    onBreakpoint() {
      this.needFade = this.swiper.slides.length !== this.swiper.params.slidesPerView;
    },
    reachEnd(e) {
      this.gradientActive = false;
    },
    reachBeginning(e) {
      this.gradientStartActive = false;
    },
    fromEdge(e) {
      this.gradientActive = true;
      this.gradientStartActive = true;
    },
    onChange() {
      this.showPrev = this.swiper.activeIndex > 0 || this.loop
      this.showNext = this.swiper.activeIndex !== (this.swiper.slides.length - 1) || this.loop
      this.$emit('change', this.swiper.activeIndex);
    },
    updateHandler() {
      if (this.swiper.$el) {
        this.swiper.update();
        this.swiper.updateSize();
        this.swiper.updateSlides();
        if(this.thumbs) {
          this.swiper.thumbs.init();
        }
      }
    },
    readyHandler() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$nextTick(() => {
          if (!this.initialized) {
            this.initialize();
          } else {
            this.updateHandler();
          }
        })
      }, 200);
    }
  }
}
</script>
