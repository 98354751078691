<template>
  <div class="relative text-white">
    <button class="cursor-pointer flex items-center" @click="toggleLang" >
      <Icon class="h-8 w-8 mr-2" :icon="'flags/' + $i18n.locale"/>
      <Icon icon="select-arrow"/>
    </button>
    <div class="absolute -left-4 right-0 top-10 bg-white rounded-md z-50 border border-solid border-gray-400"
         v-if="openLang"
         v-click-outside="toggleLang">
      <button v-for="locale in availableLocales"
              :key="locale.code"
              class="cursor-pointer flex w-full justify-center"
              @click="onClick(locale.code)">
        <Icon class="h-8 w-8 mx-4 my-2" :icon="'flags/' + locale.code"/>
      </button>
    </div>
  </div>

</template>

<script>
import Icon from '@/components/global/Icon.vue'
export default {
  name: "SelectLang",
  components: {
    Icon,
  },
  data() {
    return {
      openLang: false
    }
  },
  computed: {
    isApp() {
      return process.env.mobileApp;
    },
    availableLocales () {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale && ['en', 'fr'].includes(i.code) )
    }
  },
  methods: {
    onClick(lang) {
      this.$router.replace(this.switchLocalePath(lang))
    },
    toggleLang() {
      this.openLang = !this.openLang
    }
  }
}
</script>

<style scoped>

</style>
