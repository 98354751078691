<template>
  <header class="sticky w-full bg-white z-20"
          :class="{'top-sticky-header' : logoVisible, 'top-sticky' : !logoVisible}">
    <HeaderTopNav class="block" :class="{'hidden' : !logoVisible}"/>
    <div class="h-[55px] lg:h-auto flex items-center  content lg:my-7">
      <Back class="lg:hidden" :class="{'hidden' : !hasBack}"/>
      <nuxt-link :to="'/' + $i18n.locale + '/'"  class="w-44 lg:w-[130px] flex-shrink-0 mr-4 sm:mr-24"
              :class="{'hidden' : !logoVisible}">
        <img width="2000" height="415" :src="logo" class="w-full h-auto text-transparent" alt="Everide logo"/>
      </nuxt-link>
      <SearchInput :autoComplete="true" :type="1"/>
      <client-only>
        <ul class="hidden lg:flex ml-auto items-center">
          <li class="ml-8">
            <LinkIcon class="flex-col items-center hover:text-primary transition-colors flex" :class="{'disable-link-active': $auth.loggedIn && isProfilFavoritesRoute}" :to="localePath({name : 'profil'})">
              <ProfilPic v-if="$auth.loggedIn" :owner="true" class="w-11 h-11"/>
              <Profil v-else class="h-11"/>
              <span class="text-13" v-html="$t('header.account')"/>
            </LinkIcon>
          </li>
          <li class="ml-8">
            <LinkIcon class="flex flex-col items-center hover:text-primary transition-colors" :to="localePath({name : 'conversations'})">
              <div class="relative">
                <Message class="h-11 text-black"/>
                <span class="flex h-4 w-4 absolute right-0 top-0" v-if="messageToRead || messageToRead">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75" />
                <span class="relative inline-flex rounded-full h-4 w-4 bg-primary border border-white"></span>
               </span>
              </div>
              <span class="text-13" v-html="$t('header.messages')"/>
            </LinkIcon>
          </li>
          <li :class="{'hidden' : !$auth.user}" class="ml-8">
            <LinkIcon class="flex flex-col items-center route-exact hover:text-primary transition-colors" :to="localePath({name : 'profil-favorites'})">
              <Favorite class="h-11 w-9 text-black"/>
              <span class="text-13 " v-html="$t('header.favorites')"/>
            </LinkIcon>
          </li>
        </ul>
      </client-only>
    </div>
   <Navigation/>
  </header>
</template>

<script>

import Profil from "~/assets/svg/profil.svg?inline";
import Message from "~/assets/svg/message.svg?inline";
import Favorite from "~/assets/svg/favorite-stroke.svg?inline";
import {mapGetters} from "vuex";
import HeaderTopNav from "@/components/header/TopNav.vue"
import Back from "@/components/buttons/Back.vue"
import SearchInput from "@/components/SearchInput.vue"
import LinkIcon from "@/components/LinkIcon.vue"
import ProfilPic from "@/components/ProfilPic.vue"
import Navigation from "@/components/header/Navigation.vue"

export default {
  name: 'Header',
  components: {
    Navigation,
    ProfilPic,
    LinkIcon,
    SearchInput,
    Back,
    HeaderTopNav,
    Profil,
    Message,
    Favorite
  },
  props: {
    logoVisible: {Boolean, default: true},
    hasBack: {Boolean, default: false},
  },
  data: () => ({
    logo: process.env.staticAssets + '/site/logo-primary.svg',
    count: 0
  }),
  mounted() {
    this.count = document.getElementsByTagName('*').length;
  },
  computed : {
    ...mapGetters({
      messageToRead : 'messageToRead',
      notificationToRead: 'notificationToRead'
    }),
    isProfilFavoritesRoute() {
      return this.$route.name.indexOf('profil-favorites') >= 0
    }
  },
  methods: {
    onLogo() {
      /*if (this.$route.name && this.$route.name.indexOf('index___') === 0) {
        window.location.reload();
      }*/
    },
    onLogin() {
      this.$store.dispatch('openForm', {
        width: 'regular',
        component: 'login',
        type: 'popinV2',
        data: {
          origin: this.$route.name
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  &.nuxt-link-active, &.nuxt-link-exact-active {
    &:not(.disable-link-active) {
      @apply text-primary;
    }
  }
}
</style>
